import React from "react";
import {  IoArrowBackCircleSharp } from "react-icons/io5";
import tanjioHero2 from "../../../assets/tanjioHero2.png";
import tanjioLogo from "../../../assets/tanjioLogo.png";
import tanjio1 from "../../../assets/tanjio1.png";
import tanjio2 from "../../../assets/tanjio2.png";
import tanjio3 from "../../../assets/tanjio3.png";
import tanjio4 from "../../../assets/tanjio4.png";
import Tag from "../../../components/tag";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import 'animate.css';
import WOW from 'wowjs';
import { useEffect } from "react";

const Tanjio = () => {
  
  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: 'animate__animated',
    }).init();
  }, [])

  return (
    <>
        
      <div className="p-5 xl:px-14 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
      <button  onClick={() =>{window.history.back(); }} className="btn text-gray-500 absolute top-0 left-3">
          < IoArrowBackCircleSharp size={25}/>
          
          </button>
        <Tag text={'Anamcara Technologies Pvt Ltd'} />
        <h1 className="wow animate__fadeIn heading" data-wow-delay="0.5s">{'Tanjio - The local hyper store!!!'}</h1>
        <div className="relative">
          <div className="leftBlur w-[200px] h-[200px] xl:w-[500px] xl:h-[350px] absolute left-0 xl:-left-20 top-0"></div>
          <img src={tanjioHero2} className="wow animate__fadeIn" data-wow-delay="1s" alt="heroImage" />
          <div className="rightBlur w-[200px] h-[200px] xl:w-[500px] xl:h-[350px] absolute right-0 xl:-right-20 bottom-0"></div>
        </div>
      </div>

      <div className="bg-white xl:mt-16 m-5 xl:mx-14 rounded-2xl flex flex-col lg:flex-row justify-between items-stretch">
        <div className="p-5 xl:px-14 pt-10 flex flex-col justify-around items-start gap-8 lg:w-3/5">
          <img className="w-24 md:w-auto" src={tanjioLogo} alt="logo"/>
          <h2 className="bg-[#E9F0FF] text-sm font-medium p-3 px-4 rounded-lg">Tanjio (consumer app):</h2>
          <p className="desc">Tanjio is a local hyper store where one will find groceries, organic products, and homemade food for daily needs. This will include products that are hard to find, niche and organic in nature. A lot of home-chefs will have their products listed for the customers and will be delivered fresh for immediate consumption.</p>
        </div>
        <div className="w-full lg:flex lg:w-2/5">
          <img className="rounded-r-2xl w-full h-full object-cover" src={tanjio1} alt="tanjio1" />
        </div>
      </div>

      <div className="bg-white xl:mt-16 m-5 xl:mx-14 rounded-2xl flex flex-col lg:flex-row justify-between items-stretch">
        <div className="w-full lg:flex lg:w-2/5">
          <img className="rounded-r-2xl w-full h-full object-cover" src={tanjio2} alt="tanjio2" />
        </div>
        <div className="p-5 xl:px-14 pt-10 flex flex-col justify-around items-start gap-8 lg:w-3/5">
          <h2 className="bg-[#E9F0FF] text-sm font-medium p-3 px-4 rounded-lg">Tanjio (Business app):</h2>
          <p className="desc">Tanjio business helps home chefs to showcase their offerings to the community at large. The UI is easy to use and merchants just have to upload the images of the products and the details of the products using the mobile camera and/or using the gallery images. App standardize sizes and they are optimized for mobile screens. Here they can offer the best prices and get orders delivered to individual houses by buying just in time in bulk from OEM’s.</p>
        </div>
      </div>

      <div className="bg-white xl:mt-16 m-5 xl:mx-14 rounded-2xl flex flex-col lg:flex-row justify-between items-stretch">
        <div className="p-5 xl:px-14 pt-10 flex flex-col justify-end items-start gap-8 lg:w-3/5">
          <h2 className="bg-[#E9F0FF] text-sm font-medium p-3 px-4 rounded-lg">Tanjio Admin</h2>
          <p className="desc">Allows society admins to review, approve and administer the merchants for the societies</p>
        </div>
        <div className="w-full lg:flex lg:w-2/5">
          <img className="rounded-r-2xl w-full h-full object-cover" src={tanjio3} alt="tanjio3" />
        </div>
      </div>

      <div className="relative bg-blue-200 p-5 xl:px-14 xl:mt-16 m-5 xl:mx-14 rounded-2xl flex flex-col justify-start items-center gap-4 xl:gap-6 h-[35vh] lg:h-[50vh] mb-[10vh] sm:mb-[20vh] lg:mb-[40vh]">
        <Tag text={'technologies USED'} tech />
        <p className="desc font-medium"> Serverless architecture, Flutter - a cross platform ap p development framework. iOS and Android devices apps ,Firebase Firestore (Google's free offering) in a no-sql format, api's for payments, payment gateway - Razorpay</p>
        <img className="absolute bottom-[-10%] sm:bottom-[-40%] lg:bottom-[-50%] xl:bottom-[-70%] 2xl:bottom-[-50%]" src={tanjio4} alt="tanjio4" />
      </div>

      <div className="text-center my-20 flex flex-col items-center">
        <p className="desc font-medium">Website link</p>
        <Link to={''}>
        <button className="mt-3 w-fit btn bg-[#1E6AFF] flex justify-center items-center gap-4">
          Visit Now <BsArrowUpRight />
        </button>
        </Link>
      </div>
    </>
  );
};

export default Tanjio;