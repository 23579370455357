import React, { useEffect, useState } from 'react'
import { BsFillArrowRightCircleFill } from "react-icons/bs"
import dayone from '../../assets/dayone.png'
import oqtx from '../../assets/oqtx.png'
import bellurbis from '../../assets/bellurbis.png'
import earlystack1 from '../../assets/earlystack1.png'
import athena from '../../assets/athena.png'
import altysys from '../../assets/altysys.png'
import hashbuzz from '../../assets/hashbuzz.png'
import artist from '../../assets/artist.png'
import madari from '../../assets/madari.png'
import wedsin from '../../assets/wedsin.png'
import sgeom2 from '../../assets/sgeom2.png'
import sgeom1 from '../../assets/sgeom1.png'
import rg from '../../assets/rg.png'
import earlystack2 from '../../assets/earlystack2.png'
import farm from '../../assets/farm.png'
import algonatics from '../../assets/algonatics.png'
import radair from'../../assets/radair.png'
import spirinova from '../../assets/spirinova.png'
import jardon from '../../assets/jardon.png'
import fingerchips from '../../assets/fingerchips.png'
import sam from '../../assets/sam.png'
import flexi from '../../assets/flexi.png'
import speedgaze from '../../assets/speedgaze.png'
import tanjio from '../../assets/tanjio.png'
import ourday from '../../assets/ourday.png'
import financial from '../../assets/financial.png'
import lycee from '../../assets/lycee.png'
import textrp from '../../assets/textrp.png'
import roji from '../../assets/roji.png'
import local from '../../assets/local.png'
import kindsense from '../../assets/kindsense.png'
import debuc from '../../assets/debuc.png'
import freshcredit from '../../assets/freshcredit.png'
import redshiliz from '../../assets/redshiliz.png'
import ath from '../../assets/ath.png'
import { Link } from 'react-router-dom'
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import 'animate.css';
import WOW from 'wowjs';
import Clients from '../../components/clients'

const SucessStories = () => {
  const [sucessBtn, setSucessBtn] = useState(true);
  const [clientBtn, setClientBtn] = useState(false);
  const [techFilter, setTechFilter] = useState(true);
  // const [industryFilter, setIndustryFilter] = useState(false);
  const [filter, setFilter] = useState([]);
  let screenWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: 'animate__animated',
    }).init();
  }, [])

  const data = [
    { 
      title:"DayOne",
      desc:"An end to end solution for entry level talent and talent seekers.",
      img:dayone,
      bg:"#181D2B",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link : "story",
      id:"1",
      tag:["Mobile/Web apps", "Data Science","All"],
    },
    {
      title:"Bellurbis",
      desc:"Video streaming platform for a Teleconsulting application",
      img:bellurbis,
      bg:"#1E6AFF",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"17",
      tag:["Mobile/Web apps", "AR &VR","All"],
    },
    
    {
      title:"Redshilliz",
      desc:"NFT Marketplace",
      img:redshiliz,
      bg:"#6B7081",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"3",
      tag:["Mobile/Web apps", "Blockchain","All"],
    },
    {
      title:"Freshcredit",
      desc:"Composite Credit Score, NFT Identity and Credit DAO",
      img:freshcredit,
      bg:"#DDE9FF",
      color:"black",
      icon:"#9499AB",
      span:2,
      link:"story",
      id:"18",
      tag:["Blockchain", "Mobile/Web apps","All"]
    },
    {
      title:"DeBucINc",
      desc:"NPM Package for BSV Wallet",
      img:debuc,
      bg:"#1E6AFF",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story-2",
      id:"35",
      tag:["Blockchain","All"],
  
    },
    {
      title:"Kindsense",
      desc:
        "NFT marketplace for NFTs based on environmental preservation initiatives and charitable work"
      ,
      img:kindsense,
      bg:"#1E6AFF",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story-2",
      id:"36",
      tag:["Blockchain", "Mobile/Web apps","All"],
    },
    {
      title:"AthenaTech",
      desc:"Dune Analytics",
      img:ath,
      bg:"#181D2B",
      color:"white",
      icon:"#E6EBFE",
      span:2,
      link:"story",
      id:"33",
      tag:["Blockchain", "Data Science","All"],
    },
    {
      title:"LocalChacha",
      desc:"An Android/IoS app for hyperlocal and quick commerce",
      img:local,
      bg:"#6B7081",
      color:"white",
      icon:"#E6EBFE",
      span:2,
      link:"story",
      id:"19",
      tag:["Mobile/Web apps","All"],
    },
    {
      title:"RojiSstudios",
      img:roji,
      bg:"#DDE9FF",
      color:"black",
      icon:"#9499AB",
      span:1,
      link:"story",
      id:"20",
      tag:["Blockchain", "Mobile/Web apps","All"],
    },
    {
      title:"TextRP (Xurge Digital Media)",
      desc:"XRPL based messaging ",
      img:textrp,
      bg:"#1E6AFF",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"21",
      tag:["Blockchain", "Mobile/Web apps","All"],
    },
    {
      title:"Lycee/My Lycee",
      desc:"A modern edtech ERP and community learning platform",
      img:lycee,
      bg:"#6B7081",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"22",
      tag:["Mobile/Web apps","All"],
    },
    {
      title:"Financial Engineers",
      desc:"Anchor based saving Dapp",
      img:financial,
      bg:"#181D2B",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story-2",
      id:"37",
      tag:["Blockchain", "Mobile/Web apps","All"],
    },
    {
      title:"Ourdayscalendar.com",
      desc:"A child custody management software.",
      img:ourday,
      bg:"#181D2B",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"23",
      tag:["Mobile/Web apps","All"],
    },
    {
      title:"Tanjio",
      desc:"The local hyper store!!!",
      img:tanjio,
      bg:"#DDE9FF",
      color:"black",
      icon:"#9499AB",
      span:2,
      link:"tanjio",
      tag:["Mobile/Web apps","All"],
    },
      {
      title:"Speedgaze",
      desc:"Development of entire client and admin facing software layer",
      img:speedgaze,
      bg:"#6A6F80",
      color:"white",
      icon:"#E6EBFE",
      span:2,
      link:"story",
      id:"25",
      tag:["Cloud Computing", "Mobile/Web apps","All"],
    },
    {
      title:"MyFlexiTutor",
      desc:"An Edtech app to find tutors and coaches in the vicinity.",
      img:flexi,
      bg:"#1E6AFF",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story-2",
      id:"38",
      tag:["Mobile/Web apps","All"],
    },
    {
      title:"Sam Cohen, Entrepreneur",
      desc:"Mining backed open source app",
      img:sam,
      bg:"#E3EDFF",
      color:"black",
      icon:"#9499AB",
      span:1,
      link:"story",
      id:"12",
      tag:["Blockchain","All"],
    },
    {
      title:"Fingerchips",
      desc:"Restaurant Expense Management",
      img:fingerchips,
      bg:"#181D2B",
      color:"white",
      icon:"#E6EBFE",
      span:2,
      link:"story-2",
      id:"39",
      tag:["Mobile/Web apps","All"],
    },

    {
      title:"Jordon Wang",
      desc:
        "Keyboardless typing on VR headset, Oculus Quest 2 and Oculus Quest Pro"
      ,
      bg:"#181D2B",
      img:jardon,
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"26",
    tag:["AI & ML", "AR &VR","All"],
    },
    {
      title:"Spirinova",
      desc:"AR for 3D Specs/shades wearables",
      img:spirinova,
      bg:"#1E6AFF",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"28",
      tag:["AR &VR","All"],
    },
    {
      title:"Radair Inc",
      desc:"Helium Hostspot Firmware",
      img:radair,
      bg:"#6B7081",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"27",
      tag:["Blockchain", "Mobile/Web apps","All"],
    },
    {
      title:"Algonatics InfoAI Pvt Ltd",
      desc:"Algorithmic Trading through reinforcement learning",
      img:algonatics,
      bg:"#E9F0FF",
      color:"black",
      icon:"#6B7081",
      span:2,
      link:"story",
      id:"41",
      tag:["AI & ML", "Mobile/Web apps","All"],
    },
    {
      title:"Entertainment Farm Pvt Ltd",
      desc:"Face detection from CCTV Camera Feed",
      img:farm,
      bg:"#1E6AFF",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"40",
      tag:["AI & ML", "AR &VR","All"],
    },
    {
      title:"EarlyStack Pvt Ltd",
      desc:"OCR from scanned govt issued ",
      img:earlystack1,
      bg:"#1E6AFF",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story-2",
      id:"42",
      tag:["Mobile/Web apps","All"],
    },

    {
      title:"EarlyStack Pvt Ltd",
      desc:"Product recommendation system for e-commerce",
      img:earlystack2,
      bg:"#181D2B",
      color:"white",
      icon:"#E6EBFE",
      span:2,
      link:"story",
      id:"43",
      tag:["Mobile/Web apps","All"],
    },
    {
      title:"RG Group",
      desc:"Product placement for market basket",
      img:rg,
      bg:"#6B7081",
      color:"white",
      icon:"#E6EBFE",
      span:2,
      link:"story",
      id:"44",
      tag:["Blockchain","All"],
    },

    {
      title:"Sgeom Pvt Ltd",
      desc:"Chatbot for automatic support ticket creation",
      img:sgeom1,
      bg:"#DDE9FF",
      color:"black",
      icon:"#9499AB",
      span:1,
      link:"story",
      id:"45",
      tag:["Blockchain","All"],
    },
    {
      title:"Sgeom Pvt Ltd",
      desc:"Automatic report generation from X-Ray Image",
      img:sgeom2,
      bg:"#1E6AFF",
      color:"white",
      icon:"#DDE9FF",
      span:1,
      link:"story",
      id:"46",
      tag:["Blockchain","All"],
    },
    {
      title:"WedsIn Pvt Ltd",
      desc:"Matchmaking through personality traits",
      img:wedsin,
      bg:"#6B7081",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"47",
      tag:["Blockchain","All"],
    },
    {
      title:"DesignMadari Pvt Ltd",
      desc:"Automatics aesthetics detection in graphics design",
      img:madari,
      bg:"#181D2B",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"31",
      tag:["Blockchain","All"],
    },
    {
      title:"ArtistConnect",
      desc:"To create and promote performing arts community",
      img:artist,
      bg:"#181D2B",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"30",
      tag:["Blockchain","All"],
    },
    {
      title:"Hashbuzz.soca",
      desc:"Hashbuzz (Smart Contract)",
      img:hashbuzz,
      bg:"#DDE9FF",
      color:"black",
      icon:"#9499AB",
      span:2,
      link:"story-2",
      id:"48",
      tag:["Blockchain","All"],
    },
    {
      title:"Altysys",
      desc:"A pioneer healthcare IT service company",
      img:altysys,
      bg:"#6B7081",
      color:"white",
      icon:"#E6EBFE",
      span:2,
      link:"story",
      id:"29",
      tag:["Blockchain","All"],
    },
    {
      title:"AthenaTech",
      desc:"Data, Algorithms and Claims NFT Marketplace",
      img:athena,
      bg:"#181D2B",
      color:"white",
      icon:"#E6EBFE",
      span:1,
      link:"story",
      id:"34",
      tag:["Blockchain","All"],
    },

    {
      title:"OQTX",
      desc:"LLM based customer support system",
      img:oqtx,
      bg:"#DDE9FF",
      color:"black",
      icon:"#9499AB",
      span:2,
      link:"story-2",
      id:"49",
      tag:["Blockchain", "AI", "All"],
    },
  
  
    
    
  ]
  
  const Card = ({ title, desc, img, bg, color, icon, span, link, id }) => {
    const [zoom, setZoom] = useState('');

    return (
      <Link className={`col-span-${screenWidth > 640 ? span : '1'} drop-shadow-lg wow animate__fadeIn overflow-hidden`} to={`/success-stories/${link}/`} state={{ id: id }} onMouseEnter={() => setZoom("transition scale-110 duration-1000")} onMouseLeave={() => setZoom('transition duration-1000')}>
        <div style={{ background: bg }} className={`bg-[${bg}] text-${color} rounded-xl h-full flex flex-col justify-between`}>
          <div className="p-6">
            <div className="flex justify-between items-center gap-5">
              <h2 className="2xl:text-lg font-semibold">{title}</h2>
              <button>
                <BsFillArrowRightCircleFill
                  style={{color: icon}}
                  className={`text-xl text-[${icon}]`}
                />
              </button>
            </div>
            <p className="text-sm mt-4">{desc}</p>
          </div>
          <img className={`w-full 2xl:h-80 rounded-b-xl ${zoom}`} src={img} alt={title} />
        </div>
      </Link>
    );
  };

  const handleFilterData = (item) => {
    const isItemInArray = filter.includes(item);
    if (isItemInArray) {
      const updatedList = filter.filter(filterItem => filterItem !== item);
      setFilter(updatedList);
    } else {
      setFilter([...filter, item])
    }
  }

  const Option = ({option}) => {
    return(
      <button className={`text-sm bg-[#EEF3FF] font-medium p-2 px-5 rounded-full ${filter.includes(option) ? "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]" : ""} hover:opacity-80`} onClick={() => handleFilterData(option)}>
        {option}
      </button>
    )
  }

  return (
    <>
      <div className="p-5 xl:px-14 pt-10 xl:pt-16 flex flex-col gap-5">
        <h1 className="wow animate__fadeIn text-3xl xl:text-4xl 2xl:text-6xl font-medium">
          Exploring Boundless Innovation
        </h1>
        <div data-wow-delay="0.6s" className="flex flex-col lg:flex-row justify-between items-start gap-3 lg:mt-3">
          <p className="wow animate__fadeInLeft desc lg:w-1/2" data-wow-delay="0.5s">
            Connect with Spirinova to transform your concepts into real,
            future-proof solutions, stepping into the tech-forward landscape and
            forging a new reality.
          </p>
          <p className="wow animate__fadeInRight desc lg:w-1/2" data-wow-delay="0.5s">
            Our team is well-versed in cutting-edge tech domains and tools,
            capable of building nearly anything (except spaceships).
          </p>
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-start gap-3">
          <p className="wow animate__fadeInLeft desc lg:w-1/2" data-wow-delay="1s">
            Irrespective of your industry, process intricacy, or primary goals,
            we'll provide a tailor-made, value-driven solution.
          </p>
          <p className="wow animate__fadeInRight desc lg:w-1/2" data-wow-delay="1s">
            Explore our recent projects and see firsthand how remarkable
            innovation can be.
          </p>
        </div>
      </div>

      <div className="m-5 xl:mx-14 mt-10 xl:mt-16 p-5 xl:p-8 bg-white rounded-3xl">
        <div className='wow animate__fadeIn mb-10' data-wow-delay="1.6s">
          <div className='flex'>
            <div className='w-fit'>
              <button className={`text-base font-medium px-5 hover:text-[#1E6AFF] ${sucessBtn && "text-[#1E6AFF]"}`} onClick={() => {setSucessBtn(true); setClientBtn(false)}}>
                All Sucess Stories 
              </button>
              {sucessBtn && <div className='border-[2px] border-[#1E6AFF] rounded-t-md mt-4'></div>}
            </div>
            <div className='w-fit'>
              <button className={`text-base font-medium px-5 hover:text-[#1E6AFF] ${clientBtn && "text-[#1E6AFF]"}`} onClick={() => {setClientBtn(true); setSucessBtn(false)}}>
                Client Testimonials
              </button>
              {clientBtn && <div className='border-[2px] border-[#1E6AFF] rounded-t-md mt-4'></div>}
            </div>
          </div>
        </div>
       
        {sucessBtn && <div>
          <div className='wow animate__fadeIn font-medium flex gap-8' data-wow-delay="2s">
            <button className='flex items-center gap-3 hover:opacity-80' onClick={() => {setTechFilter(!techFilter);}}>
              Technologies
              {techFilter ? <FaChevronUp className='text-sm text-[#8F9199]' /> :
              <FaChevronDown className='text-sm text-[#8F9199]' />}
            </button>
            {/* <button className='flex items-center gap-3 hover:opacity-80' onClick={() => {setIndustryFilter(!industryFilter); setTechFilter(false)}}>
              Industries
              {industryFilter ? <FaChevronUp className='text-sm text-[#8F9199]' /> :
              <FaChevronDown className='text-sm text-[#8F9199]' />}
            </button> */}
          </div>
          <div className='border my-4'></div>
          {techFilter && <div className=' wow animate__fadeIn flex flex-wrap gap-4' data-wow-delay="2.5s">
          <Option option="All" />
            <Option option="Blockchain" />
            <Option option="Data Science" />
            <Option option="AI & ML" />
            <Option option="AR &VR" />
            <Option option="Metaverse" />
            <Option option="IoT (Connected Device)" />
            <Option option="Mobile/Web apps" />
            <Option option="Cloud Computing" />
            
          </div>}
          {/* {industryFilter && <div className='flex flex-wrap gap-4'>
            <Option option="Industries" />
            <Option option="Data Industries" />
            <Option option="AI & Industries" />
            <Option option="AR &Industries" />
            <Option option="Industries" />
            <Option option="IoT Industries" />
          </div>} */}

          <div className="mt-20 grid sm:grid-cols-2 xl:grid-cols-3 2xl:px-[10vw] items-stretch gap-5 2xl:gap-8" data-wow-delay="3s">
            {data.map((item) => {
                if (filter.length === 0) {
                  return (
                    <Card
                      title={item.title}
                      desc={item.desc}
                      img={item.img}
                      bg={item.bg}
                      color={item.color}
                      icon={item.icon}
                      span={item.span}
                      link={item.link}
                      id={item.id}
                      key={item.id}
                    />
                  );
                }

                const hasMatchingTag = item.tag.some(tag => filter.includes(tag));
                if (hasMatchingTag) {
                  return (
                    <Card
                      title={item.title}
                      desc={item.desc}
                      img={item.img}
                      bg={item.bg}
                      color={item.color}
                      icon={item.icon}
                      span={item.span}
                      link={item.link}
                      id={item.id}
                      key={item.id}
                    />
                  );
                }
                return null;
            })}
         {/*   <Card
              title={"DayOne"}
              desc="An end to end solution for entry level talent and talent seekers."
              img={dayone}
              bg={"#181D2B"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="1"
            />
            <Card
              title={"Bellurbis"}
              desc="Video streaming platform for a Teleconsulting application"
              img={bellurbis}
              bg={"#1E6AFF"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="17"
            />
            <Card
              title={"Redshilliz"}
              desc="NFT Marketplace"
              img={redshiliz}
              bg={"#6B7081"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="3"
            />
            <Card
              title={"Freshcredit"}
              desc="Composite Credit Score, NFT Identity and Credit DAO"
              img={freshcredit}
              bg={"#DDE9FF"}
              color={"black"}
              icon={"#9499AB"}
              span={2}
              link="story"
              id="18"
            />
            <Card
              title={"DeBucINc"}
              desc="NPM Package for BSV Wallet"
              img={debuc}
              bg={"#1E6AFF"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story-2"
              id="35"
          
            />
            <Card
              title={"Kindsense"}
              desc={
                "NFT marketplace for NFTs based on environmental preservation initiatives and charitable work"
              }
              img={kindsense}
              bg={"#1E6AFF"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story-2"
              id="36"
            />
            <Card
              title={"AthenaTech"}
              desc={"Dune Analytics"}
              img={ath}
              bg={"#181D2B"}
              color={"white"}
              icon={"#E6EBFE"}
              span={2}
              link="story"
              id="33"
            />
            <Card
              title={"LocalChacha"}
              desc={"An Android/IoS app for hyperlocal and quick commerce"}
              img={local}
              bg={"#6B7081"}
              color={"white"}
              icon={"#E6EBFE"}
              span={2}
              link="story"
              id="19"
            />
            <Card
              title={"RojiSstudios"}
              img={roji}
              bg={"#DDE9FF"}
              color={"black"}
              icon={"#9499AB"}
              span={1}
              link="story"
              id="20"
            />
            <Card
              title={"TextRP (Xurge Digital Media)"}
              desc={"XRPL based messaging "}
              img={textrp}
              bg={"#1E6AFF"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="21"
            />
            <Card
              title={"Lycee/My Lycee"}
              desc={"A modern edtech ERP and community learning platform"}
              img={lycee}
              bg={"#6B7081"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="22"
            />
            <Card
              title={"Financial Engineers"}
              desc={"Anchor based saving Dapp"}
              img={financial}
              bg={"#181D2B"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story-2"
              id="37"
            />
            <Card
              title={"Ourdayscalendar.com"}
              desc={"A child custody management software."}
              img={ourday}
              bg={"#181D2B"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="23"
            />
            <Card
              title={"Tanjio"}
              desc={"The local hyper store!!!"}
              img={tanjio}
              bg={"#DDE9FF"}
              color={"black"}
              icon={"#9499AB"}
              span={2}
              link="tanjio"
            />
            <Card
              title={"Speedgaze"}
              desc={
                "Development of entire client and admin facing software layer"
              }
              img={speedgaze}
              bg={"#6A6F80"}
              color={"white"}
              icon={"#E6EBFE"}
              span={2}
              link="story"
              id="25"
            />
            <Card
              title={"MyFlexiTutor"}
              desc={"An Edtech app to find tutors and coaches in the vicinity."}
              img={flexi}
              bg={"#1E6AFF"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story-2"
              id="38"
            />
            <Card
              title={"Sam Cohen, Entrepreneur"}
              desc={"Mining backed open source app"}
              img={sam}
              bg={"#E3EDFF"}
              color={"black"}
              icon={"#9499AB"}
              span={1}
              link="story-2"
              id="12"
            />
            <Card
              title={"Fingerchips"}
              desc={"Restaurant Expense Management"}
              img={fingerchips}
              bg={"#181D2B"}
              color={"white"}
              icon={"#E6EBFE"}
              span={2}
              link="story-2"
              id="39"
            />
            <Card
              title={"Jordon Wang"}
              desc={
                "Keyboardless typing on VR headset, Oculus Quest 2 and Oculus Quest Pro"
              }
              bg={"#181D2B"}
              img={jardon}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="26"
            />
            <Card
              title={"Spirinova"}
              desc={"AR for 3D Specs/shades wearables"}
              img={spirinova}
              bg={"#1E6AFF"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="28"
            />
            <Card
              title={"Radair Inc"}
              desc={"Helium Hostspot Firmware"}
              img={radair}
              bg={"#6B7081"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="27"
            />
            <Card
              title={"Algonatics InfoAI Pvt Ltd"}
              desc={"Algorithmic Trading through reinforcement learning"}
              img={algonatics}
              bg={"#E9F0FF"}
              color={"black"}
              icon={"#6B7081"}
              span={2}
              link="story"
              id="41"
            />
            <Card
              title={"Entertainment Farm Pvt Ltd"}
              desc={"Face detection from CCTV Camera Feed"}
              img={farm}
              bg={"#1E6AFF"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="40"
            />
            <Card
              title={"EarlyStack Pvt Ltd"}
              desc={"OCR from scanned govt issued "}
              img={earlystack1}
              bg={"#1E6AFF"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story-2"
              id="42"
            />
            <Card
              title={"EarlyStack Pvt Ltd"}
              desc={"Product recommendation system for e-commerce"}
              img={earlystack2}
              bg={"#181D2B"}
              color={"white"}
              icon={"#E6EBFE"}
              span={2}
              link="story"
              id="43"
            />
            <Card
              title={"RG Group"}
              desc={"Product placement for market basket"}
              img={rg}
              bg={"#6B7081"}
              color={"white"}
              icon={"#E6EBFE"}
              span={2}
              link="story"
              id="44"
            />
            <Card
              title={"Sgeom Pvt Ltd"}
              desc={"Chatbot for automatic support ticket creation"}
              img={sgeom1}
              bg={"#DDE9FF"}
              color={"black"}
              icon={"#9499AB"}
              span={1}
              link="story"
              id="45"
            />
            <Card
              title={"Sgeom Pvt Ltd"}
              desc={"Automatic report generation from X-Ray Image"}
              img={sgeom2}
              bg={"#1E6AFF"}
              color={"white"}
              icon={"#DDE9FF"}
              span={1}
              link="story"
              id="46"
            />
            <Card
              title={"WedsIn Pvt Ltd"}
              desc={"Matchmaking through personality traits"}
              img={wedsin}
              bg={"#6B7081"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="47"
            />
            <Card
              title={"DesignMadari Pvt Ltd"}
              desc={"Automatics aesthetics detection in graphics design"}
              img={madari}
              bg={"#181D2B"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="31"
            />
            <Card
              title={"ArtistConnect"}
              desc={"To create and promote performing arts community"}
              img={artist}
              bg={"#181D2B"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="30"
            />
            <Card
              title={"Hashbuzz.soca"}
              desc={"Hashbuzz (Smart Contract)"}
              img={hashbuzz}
              bg={"#DDE9FF"}
              color={"black"}
              icon={"#9499AB"}
              span={2}
              link="story-2"
              id="48"
            />
            <Card
              title={"Altysys"}
              desc={"A pioneer healthcare IT service company"}
              img={altysys}
              bg={"#6B7081"}
              color={"white"}
              icon={"#E6EBFE"}
              span={2}
              link="story"
              id="29"
            />
            <Card
              title={"Athena Tech"}
              desc={"Data, Algorithms and Claims NFT Marketplace"}
              img={athena}
              bg={"#181D2B"}
              color={"white"}
              icon={"#E6EBFE"}
              span={1}
              link="story"
              id="34"
            /> */}
          </div>
        </div>}

        {clientBtn && <Clients />}

      </div>
    </>
  );
};

export default SucessStories;