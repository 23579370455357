import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../index.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { BsArrowRight } from "react-icons/bs";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { baseURL } from "../../constants";
import companyBg from "../../assets/companyBg.png";
import Tag from "../../components/tag";
import BlogCard from "../../components/blog-card";
import avatar from "../../assets/avatar.png";
import connection from "../../assets/connection.png";
import blog2 from "../../assets/blog2.png";
import blog3 from "../../assets/blog3.png";
import BlockHero from "../../assets/BlockHero.png";
import banner2 from "../../assets/banner-2.png";
import artlog1 from "../../assets/artlog2.png";
import altlog1 from "../../assets/altlog2.png";
import redlog1 from "../../assets/redlog2.png";
import radlog1 from "../../assets/radlog2.png";
import talog1 from "../../assets/talog2.png";
import tanlog1 from "../../assets/tanlog2.png";
import xlog1 from "../../assets/xlog2.png";
import athlog1 from "../../assets/athlog2.png";
import belog1 from "../../assets/belog2.png";
import daylog1 from "../../assets/daylog2.png";
import speedlog1 from "../../assets/speedlog2.png";
import oqlog1 from "../../assets/oqlog2.png";
import freshlog1 from "../../assets/freshlog2.png";
import redlap from "../../assets/redlap.png";
import sammmco from "../../assets/sammmco.png";
import spiri from "../../assets/spiri.png";
import hel2 from "../../assets/hel2.png";
import dune from "../../assets/dune.png";
import Security from "../../assets/Security.png";
import Credit from "../../assets/Credit.png";
import DigitalRecords from "../../assets/Digital Records.png";
import Ecommerce from "../../assets/E-commerce.png";
import Streaming from "../../assets/Streaming.png";
import FinTech from "../../assets/Fintech.png";
import Gaming from "../../assets/Gaming.png";
import ARVR from "../../assets/ARVR.png";
import Lending from "../../assets/Lending.png";
import PaymentTech from "../../assets/Payment Tech.png";
import SocialProfessionalMedia from "../../assets/Social Professional Media.png";
import Stock from "../../assets/Stock.png";
import DigitalIdentify from "../../assets/Digital Identify.png";
import Sustainability from "../../assets/Sustainability.png";
import Supply from "../../assets/Supply.png";
import HealthCare from "../../assets/HealthCare.png";
import Realestate from "../../assets/Realestate.png";
import IOTSolutions from "../../assets/IOT Solutions.png";
import Insurance from "../../assets/Insurance.png";
import StorageData from "../../assets/StorageData.png";
import "animate.css";
import WOW from "wowjs";
import homblog from "../../assets/homblog.png";
import pdf from "../../assets/Spirinova Portfolio.pdf";

const Home = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: "animate__animated",
    }).init();
  }, []);

  const downloadPdf = () => {
    setLoading(true);
    const link = document.createElement("a");
    link.href = pdf;
    link.download = "Spirinova Portfolio.pdf";
    link.click();
    setLoading(false); 
  };

  const AchiveData = ({ num, title, delay, link }) => (
    <Link to={link}>
      <div
        className="wow animate__fadeIn bg-white rounded-2xl text-center p-5 w-32 xl:w-52 xl:h-28 card flex flex-col  justify-center items-strech" style={{height: '120px'}}
        data-wow-delay={delay}
      >
        <h1 className="text-[#1E6AFF] text-xl xl:text-3xl font-bold">{num}</h1>
        <h2 className="text-black font-semibold text-sm xl:text-base">
          {title}
        </h2>
      </div>
    </Link>
  );

  const portfolio = async () => {
    const response = await axios.get(`${baseURL}/api/portfolio`);

    // URL of the PDF file
    // const pdfUrl = 'http://localhost:4444/uploads/Spirinova_Updated_Portfolio_27_Oct_1_8f9a485d21.pdf';
    const pdfUrl = `${baseURL}${response.data.data.attributes.file}`;

    try {
      // Fetch the PDF file as a Blob
      const response = await fetch(pdfUrl);
      const blob = await response.blob();

      // Create a temporary anchor element
      const anchor = document.createElement("a");
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = "Spirinova-Portfolio.pdf"; // Set the filename for the downloaded file

      // Trigger a click event on the anchor to start the download
      anchor.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const ClientLogo = ({ img }) => (
    <div
      className={`indusCard p-3  w-35 h-10 flex justify-center items-center gap-5 `}
    >
      <div className="  flex justify-center items-center  rounded-lg object-fit">
        <img className="w-3/4" src={img} alt="clientLogo" />
      </div>
      {/* <h2 className='text-base xl:text-xl font-medium break-keep'>{title}</h2> */}
    </div>
    // <div className='bg-[#DDDDE9] w-40 h-10 flex justify-center items-center rounded-lg'>
    //     <img className='' src={img} alt="clientLogo" />
    // </div>
  );

  const ServiceCard = ({ title, sub, para, delay }) => (
    <div
      className="wow animate__fadeIn bg-[#282E3C] p-6 rounded-2xl w-full xl:w-[340px] text-left flex flex-col gap-6"
      data-wow-delay={delay}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-lg font-medium">{title}</h1>
        {/* <img src={arrow} /> */}
      </div>
      <h2 className="text-sm font-medium text-[#1E6AFF]">{sub}</h2>
      <p className="text-sm text-[#F5F5F5]">{para}</p>
    </div>
  );

  const IndustryCard = ({ logo, title }) => (
    <div
      className={`indusCard p-3 px-5 flex justify-between items-center gap-3 w-fit `}
    >
      <div className="w-12 h-12 rounded-full bg-[#191D2C] flex justify-center items-center">
        <img className="" src={logo} alt={title} />
      </div>
      <h2 className="text-base xl:text-xl font-medium break-keep">{title}</h2>
    </div>
  );

  const SuccessCard = ({ bg, color, title, sub, para, link, id, img }) => {
    const [zoom, setZoom] = useState("");
    // const [cardHeight, setCardHeight] = useState('auto');

    // useEffect(() => {
    //     // Calculate the height of the tallest card in the current row
    //     const cards = document.querySelectorAll('.success-card');
    //     const heights = Array.from(cards).map(card => card.clientHeight);
    //     const maxCardHeight = Math.max(...heights);

    //     // Set the height of all cards in the row to the height of the tallest card
    //     setCardHeight(maxCardHeight + 'px');
    //   }, []); // Run this effect only once after the component mounts

    return (
      <Link
        to={link}
        state={{ id: id }}
        onMouseEnter={() => setZoom("transition scale-110 duration-1000")}
        onMouseLeave={() => setZoom("transition duration-1000")}
        style={{ height: "100%" }}
      >
        <div
          className={`bg-${bg} text-[${color}] p-6 pb-0 w-full rounded-2xl text-left card flex flex-col items-center h-auto gap-6 overflow-hidden `}
        >
          <div className=" w-full flex justify-between items-center " >
            <h1 className={`text-lg xl:text-2xl text-[${color}] font-medium`}>
              {title}
            </h1>
            <FiArrowUpRight className="text-3xl" />
          </div>
          <h2
            className={`px-6 w-full text-sm xl:text-lg text-[${color}] font-medium uppercase `}
          >
            {sub}
          </h2>
          <p className="px-6 w-full text-sm xl:text-base ">{para}</p>
          <div className="w-full flex justify-center items-center ">
            <img
              className={`${
                title === "NFT Marketplace" ? "" : "w-full h-auto "
              } ${zoom}`}
              src={img}
              alt={title}
            />
          </div>
        </div>
      </Link>
    );
  };

  const AchivData = ({ num, title, link }) => (
    <Link
      to={link}
      className=" card bg-white rounded-2xl p-6 xl:p-12 flex flex-col  justify-between text-center "
    >
      <div className="bg-white  flex flex-col  items-center h-full   ">
        <h1 className="text-[#1E6AFF] text-2xl xl:text-4xl font-bold">{num}</h1>
        <h2 className="font-semibold text-base xl:text-xl">{title}</h2>
      </div>
    </Link>
  );
  // const AchiveData = ({ num, title, delay, link }) => (
  //   <Link 
  //     to={link}
  //     className="card bg-white rounded-2xl p-5 w-32 xl:w-52 flex flex-col  justify-center text-center  "
  //   >
  //     <div
  //       className="wow animate__fadeIn bg-white rounded-2xl flex flex-col  items-center "
  //       data-wow-delay={delay}
  //     >
  //       <h1 className="text-[#1E6AFF] text-xl xl:text-3xl font-bold">{num}</h1>
  //       <h2 className="text-black font-semibold text-sm xl:text-base">
  //         {title}
  //       </h2>
  //     </div>
  //   </Link>
  // );

  // const AchiveData = ({ num, title, delay, link }) => (
  //   <Link 
  //     to={link}
  //     style={{
  //       textDecoration: 'none',
  //       // width: '100%',
  //       marginBottom: '20px', // Add margin between cards
  //       display: 'flex',
  //       // justifyContent: 'space-between',
  //       alignItems: 'stretch', // Make all cards stretch to the same height
  //     }}
  //   >
  //     <div
  //       style={{
  //          width: 'calc(33.33% - 20px)', // Make each card expand to fill available space
  //         display: 'flex',
  //         background :"white",
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         padding: '20px',
  //         border: '1px ',
  //         borderRadius: '10px',
  //       }}
  //       data-wow-delay={delay}
  //     >
  //       <h1 style={{ color: '#1E6AFF', fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>{num}</h1>
  //       <h2 style={{ color: 'black', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>{title}</h2>
  //     </div>
  //   </Link>
  // );
  

  

  const FaqCard = ({ title, desc, link }) => {
    const [active, setActive] = useState(false);

    return (
      <button
        className="md:w-1/2 bg-white rounded-xl px-8 py-6 drop-shadow"
        onClick={() => setActive(!active)}
      >
        <div className="flex justify-between items-center gap-6">
          <h1 className="text-sm xl:text-lg font-medium">{title}</h1>
          <button>{!active ? <FaPlus /> : <FaMinus />}</button>
        </div>
        <Link to={link}>
          <div
            className={`text-left text-sm text-gray-500 xl:text-lg mt-1 ${
              active ? "block animate__animated animate__fadeIn" : "hidden"
            }`}
          >
            {desc}
          </div>
        </Link>
      </button>
    );
  };

  return (
    <>
      <div className="relative">
        <Swiper
          spaceBetween={5}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="h-[65vh] sm:h-[35vh] lg:h-[65vh] 2xl:h-[90vh] text-white relative"
        >
          <SwiperSlide>
            <img
              className=" w-full h-full object-cover"
              src={companyBg}
              alt="BlockHero"
            />

            <div className="wow animate__fadeIn absolute bottom-0 xl:bottom-10 2xl:bottom-20 flex flex-col justify-center items-start gap-5 lg:gap-8 xl:gap-10 pt-2 p-5 py-10 xl:p-10">
              <h1
                className="text-3xl xl:text-4xl 2xl:text-6xl font-medium lg:w-2/5 "
                style={{ transform: "translateY(20%)" }}
              >
                From Concept to Reality: Crafting Products that Redefine
                Excellence.
              </h1>
              <h2
                className="2xl:text-lg lg:w-1/4"
                style={{ transform: "translateY(30%)" }}
              >
                Delivering a top-tier customer experience to ideate and develop
                products that shape the future
              </h2>
              <button
                className="btn bg-[#1E6AFF] flex items-center gap-3"
                style={{ transform: "translateY(20%)" }}
                onClick={downloadPdf}
              >
                Download Portfolio
              </button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="w-full h-full object-cover"
              src={BlockHero}
              alt="BlockHero"
            />
            <div className="wow animate__fadeIn absolute bottom-0 xl:bottom-10 2xl:bottom-20 flex flex-col justify-center items-start gap-5 lg:gap-8 xl:gap-10 p-5 py-10 xl:p-10">
              <h1 className="text-3xl xl:text-4xl 2xl:text-6xl font-medium lg:w-2/5">
                Leading the way in Blockchain Excellence across various Chains
              </h1>
              <h2 className="2xl:text-lg lg:w-1/4">
                We specialize in Blockchain Consulting & Development, NFT
                Marketplace, Smart Contract, DApp, Crypto & ICO.
              </h2>
              <button className="btn bg-[#1E6AFF] flex items-center gap-3">
                Explore our blockchain projects <BsArrowRight />
              </button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="w-full h-full object-cover"
              src={banner2}
              alt="banner2"
            />
            <div className="wow animate__fadeIn absolute bottom-0 xl:bottom-10 2xl:bottom-20 flex flex-col justify-center items-start gap-5 lg:gap-8 xl:gap-10 p-5 py-10 xl:p-10">
              <h1 className="text-3xl xl:text-4xl 2xl:text-6xl font-medium lg:w-2/5">
                Get your MVPs starting $9,999 in less than 2 weeks.
              </h1>
              <h2 className="2xl:text-lg lg:w-1/4">
                Build your MVP using our in house Product Earlystack.
              </h2>
              <button className="btn bg-[#1E6AFF] flex items-center gap-3">
                Explore Earlystack <BsArrowRight />
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
        <div
          className="absolute bottom-[-55vh] sm:bottom-[-9vh] md:bottom-[-9vh] xl:-bottom-12 right-0 left-0 mx-auto transform translate-y-10 flex flex-wrap justify-center items-center gap-5 xl:gap-10"
          style={{ zIndex: 1 }}
        >
          <AchiveData 
            num={"50 +"}
            title="Successful projects"
            delay="0.3s"
            link={"/success-stories"}
          />
          <AchiveData
            num={"100 %"}
            title="Successful Rate"
            delay="0.6s"
            link={"/success-stories"}
          />
          <AchiveData
            num={"20 +"}
            title="Large scale projects"
            delay="0.9s"
            link={"/success-stories"}
          />
          <AchiveData
            num={"10 +"}
            title="Countries served"
            delay="1.2s"
            link={"/success-stories"}
          />

          <AchiveData
            num={"35 +"}
            title="Years of founders experience"
            delay="1.5s"
          />
        </div>
      </div>

      <div className="pt-[60vh] sm:pt-[25vh] md:pt-[10vh] xl:p-10"></div>

      <div className="logoSwiper p-2 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
        <Tag text={"OUR VALUABLE CLIENTS"} />
        <h1 className="heading">Proud to be reliable technology partner</h1>
        <Swiper
          speed={8000}
          slidesPerView={8}
          spaceBetween={36}
          loop={true}
          autoplay={{
            delay: 1,
            freeMode: true,
            disableOnInteraction: false,
          }}
          showsPagination={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="wow animate__fadeIn w-full mt-4 xl:mt-7"
          breakpoints={{
            300: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
            },
            1280: {
              slidesPerView: 7,
            },
            1536: {
              slidesPerView: 9,
            },
          }}
        >
          <SwiperSlide className="w-fit">
            <ClientLogo img={radlog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={redlog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={artlog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={athlog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={altlog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={belog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={speedlog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={freshlog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={tanlog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={talog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={xlog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={daylog1} />
          </SwiperSlide>
          <SwiperSlide className="w-fit">
            <ClientLogo img={oqlog1} />
          </SwiperSlide>
        </Swiper>

        <div className="w-[95%] mt-7 mb-7 xl:mt-14 text-white bg-[#181D2B] flex flex-col justify-center items-center gap-4 xl:gap-6 pt-5 px-5 xl:pt-10 rounded-3xl">
          <Tag className="mb-7" text={"our expertise"} />
          <h1 className="heading">Key Tech Offerings at Spirinova</h1>
          <div className="mt-7 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
            <ServiceCard
              title={"Product Consulting"}
              sub={"R&D, business & tech strategy, estimations"}
              para={
                "We provide all product management consulting services including user research, SEO, marketing, product feature maps, roadmaps, product solutioning, growth targets and investors presentation decks"
              }
            />
            <ServiceCard
              title={"Product Development"}
              sub={"Custom ledger tech engineering & advisory"}
              para={
                "Turning ideas into reality, we offer comprehensive product development services. Our specialization lies in smart contract solutions across multiple blockchain platforms."
              }
              delay="0.3s"
            />
            <ServiceCard
              title={"IT Consulting"}
              sub={"R&D, business & tech strategy, estimations"}
              para={
                "We help you turn your innovative ideas into competitive market-ready solutions. Let’s refine your digital strategies, map out neat infrastructures, and jump-start ROI.s"
              }
              delay="0.6s"
            />
            <ServiceCard
              title={"Custom software development"}
              sub={"Crafted tech of any scale & complexity"}
              para={
                "From square one to engineering excellence! Spirinova assists in full-cycle software development, jumps in to take it over, or brings  dedicated top-demanded skills."
              }
              delay="0.9s"
            />
            <ServiceCard
              title={"Mobile Application Development"}
              sub={"Business purpose-driven apps"}
              para={
                "Get your users a failure-proof tool for business or leisure right at their fingertips. Mobile-first and user-centric, your apps are designed to steal the show."
              }
              delay="1.2s"
            />
            <ServiceCard
              title={"Web Development"}
              sub={"Websites, UXs, accessibility"}
              para={
                "We build custom, secure, and scalable solutions. Consult our specialists to develop strategy for niche DLT solutions and decentralized ecosystems."
              }
              delay="1.5s"
            />
            <ServiceCard
              title={"UI/UX designs"}
              sub={"Research, UI/UX design & consulting"}
              para={
                "Unleash your creative vision! Let’s make your software solutions wowsome on the front, power-maxed at the back, and stunning users at a glance."
              }
              delay="1.8s"
            />
            <ServiceCard
              title={"QA & Audits"}
              sub={"Software audit, consulting, testing automation"}
              para={
                "Deal with human and system errors integrally. Spirirnova quality assurance pays off through your solution's increased security and performance."
              }
              delay="2.1s"
            />
          </div>
        </div>
      </div>

      <div className="logoSwiper indusSwiper blurBg p-5 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
        <h1 className="heading">Industries we cater to</h1>
        <Swiper
          speed={5000}
          centeredSlides={true}
          slidesPerView="auto"
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 1,
            freeMode: true,
            disableOnInteraction: false,
          }}
          showsPagination={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="wow animate__fadeIn w-full mt-4 xl:mt-7"
        >
          <SwiperSlide>
            <IndustryCard logo={FinTech} title={"FinTech"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={HealthCare} title={"HealthCare"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Supply} title={"Supply Chain"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Realestate} title={"Realestate"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Ecommerce} title={"E-commerce"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={IOTSolutions} title={"IOT Solutions"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Insurance} title={"Insurance"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={DigitalRecords} title={"Digital Records"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard
              logo={StorageData}
              title={"Storage & Data Mangement"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={ARVR} title={"AR/VR"} />
          </SwiperSlide>
        </Swiper>
        <Swiper
          speed={5000}
          centeredSlides={true}
          slidesPerView="auto"
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 1,
            // reverseDirection: true,
            freeMode: true,
            disableOnInteraction: false,
          }}
          showsPagination={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="wow animate__fadeIn w-full mt-2"
          delay="0.5s"
        >
          <SwiperSlide>
            <IndustryCard logo={DigitalIdentify} title={"Digital Identify"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard
              logo={SocialProfessionalMedia}
              title={"Social Professional Media"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Gaming} title={"Gaming"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Stock} title={"Stock/NFT/Crypto Currency"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={PaymentTech} title={"Payment Tech"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard
              logo={Sustainability}
              title={"Sustainability/ESG Products"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Lending} title={"Lending"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Streaming} title={"Streaming"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Credit} title={"Customized Credit Scoring"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Streaming} title={"Streaming"} />
          </SwiperSlide>
          <SwiperSlide>
            <IndustryCard logo={Security} title={"Security"} />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="p-5 xl:px-14 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
        <Tag text={"Success stories"} />
        <h1 className="heading">
          Remarkable success stories of our valued clients.
        </h1>
        <p className="desc text-gray-500 xl:w-5/6">
          When it comes to innovation, we help figure out what’s your thing.
          Don’t save your ideation attempts for another day. Check if we have
          something similar to what you have in mind. And what our clients have
          to say about us
        </p>
        <div className="w-full flex flex-col gap-4 xl:gap-10 " >
          <div className="grid lg:grid-cols-2 gap-4 xl:gap-10" >
            <SuccessCard 
              bg={"[#1E6AFF]"}
              color={"#F5F5F5"}
              title={"NFT Marketplace"}
              sub={"Steinova"}
              para={
                "Redshilliz Product is a Digital marketplace for crypto collectibles and non-fungible tokens (NFTs). This is for Buy, Sell, and discover exclusive digital art NFTs . This product is European Union (EU) compliant and developed with almost all the features as OpenSea. USP of the product is its a meta NFT marketplace which shows listing of all available and validated NFTs."
              }
              link="/success-stories/story"
              id="3"
              img={redlap}
            />
            <div className="text-left flex flex-col justify-between gap-4 xl:gap-10">
              <div className="bg-[#181D2B] text-white p-6 rounded-2xl">
                <div className="flex items-center gap-5">
                  <img src={avatar} alt="avatar" />
                  <div>
                    <h1 className="font-semibold text-base xl:text-xl">
                      Will Moreno
                    </h1>
                    <h2 className="text-sm xl:text-base">Founder, TextRP</h2>
                  </div>
                </div>
                <p className="text-sm xl:text-base text-white/50 mt-4">
                  "From the very beginning of the project, Spirinova has shown a
                  deep understanding of our needs and requirements. They have
                  been proactive in their communication and have been
                  consistently delivering on-time and within budget. Their team
                  has also been extremely flexible and responsive to...
                </p>
              </div>
              <div className="bg-white p-6 rounded-2xl">
                <div className="flex items-center gap-5">
                  <img src={sammmco} alt="sammmco" />
                  <div>
                    <h1 className="font-semibold text-base xl:text-xl">
                      Sam Cohen
                    </h1>
                    <h2 className="text-sm xl:text-base">Founder, TextRP</h2>
                  </div>
                </div>
                <p className="text-sm xl:text-base text-[#676D79] mt-4">
                  "It was very pleasing working experience with Spirinova team
                  on mining system development. I was impressed by the depth of
                  their knowledge, ability to solve problems quickly and
                  enthusiasm. I would recommend Spiri...
                </p>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 gap-4 xl:gap-10 " >
            <SuccessCard 
              bg={"white"}
              color={"grey-500"}
              title={"AR for 3D Specs/shades wearables"}
              sub={"Spirinova"}
              para={
                "An AR application for trying eyewears on your image or video. Model trained by deep learning was used to detect eye’s location in the image..."
              }
              link="/success-stories/story"
              id="28"
              img={spiri}
            />
            <SuccessCard 
              bg={"[#181D2B]"}
              color={"#F5F5F5"}
              title={"Helium Hostspot Firmware"}
              sub={"Radair Inc"}
              para={
                "Helium is an IoT flavored blockchain. The activity of IoT devices are recorded as transactions in the helium blockchain. Helium hotspot is a..."
              }
              link="/success-stories/story"
              id="27"
              img={hel2}
            />
            <SuccessCard 
              bg={"[#1E6AFF]"}
              color={"#F5F5F5"}
              title={"Dune Analytics"}
              sub={"Athena Tech"}
              para={
                "Created queries and chart visualizations for POZ tokens. Dune connects to various block explorers and provides a SQL like query engine and a..."
              }
              link="/success-stories/story"
              id="33"
              img={dune}
            />
          </div>
        </div>
        <Link to="/success-stories">
          <button className=" btn text-black   bg-transparent border border-black">
            Explore all Projects
          </button>
        </Link>

        <div className="w-full mt-10 bg-[#181D2B] text-white text-left rounded-2xl p-4 xl:p-10 flex flex-col lg:flex-row justify-center lg:justify-between items-center gap-5">
          <div className="flex flex-col justify-between items-start gap-5 xl:w-1/2">
            <h1 className="heading">Connect to our consultants</h1>
            <h2 className="desc text-white/50">
              Spirinova can handle tech advisory with regards to your domain
              specifics and help decide upon the project rationale. Leave your
              contacts below and we’ll get back to you within 24 hours.
            </h2>
            <Link to="#contact">
              <button className="btn bg-[#1E6AFF]">Contact Us</button>
            </Link>
          </div>
          <div className="xl:w-1/2 flex justify-end items-end">
            <img src={connection} alt="connection" />
          </div>
        </div>
      </div>

      <div className="p-5 xl:px-14 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
        <Tag text={"Achievements"} />
        <h1 className="heading">The achievements that make our history</h1>
        <p className="desc xl:w-5/6">
          Scroll through our recent articles — we prepare a good reading for
          tech newbies and pros alike to help make educated business decisions.
        </p>
        <div className="mt-5 grid lg:grid-cols-2 gap-5">
          <div className="getToBg p-5 text-white text-left rounded-2xl flex flex-col justify-between gap-4 xl:gap-6 items-start">
            <h2 className="desc font-semibold uppercase">WHAT WE DO?</h2>
            <p className="xl:w-3/5">
              We specialize in generative AI-driven software development for
              startups, leveraging our proprietary platform. Our expertise lies
              in crafting custom solutions that drive growth and efficiency.
              From concept to deployment, we deliver cutting-edge technologies,
              empowering our clients to succeed in the digital age.
            </p>
            <Link to="/company">
              <button className="btn bg-transparent border border-white">
                Get to know us
              </button>
            </Link>
          </div>
          <div className="grid grid-cols-2 gap-5 ">
            <AchivData
              num={"50+"}
              title={"Successful projects"}
              link={"/success-stories"}
            />
            <AchivData
              num={"100%"}
              title={"Successful Rate"}
              link={"/success-stories"}
            />
            <AchivData
              num={"35+"}
              title={"Years of Founders Experience"}
              link={"/company#leadership"}
            />
            <AchivData
              num={"10+"}
              title={"Countries served"}
              link={"/success-stories"}
            />
          </div>
        </div>
      </div>

      <div className="p-5 xl:px-14 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
        <Tag text={"QUESTIONS"} />
        <h1 className="heading">Frequently asked questions</h1>
        <p className="desc xl:w-5/6">
          Everything you need to know about Spirinova. Can’t find the answer
          you’re looking for? Feel free to{" "}
          <Link to="#contact" className="text-[#1E6AFF]">
            contact us
          </Link>
        </p>
      </div>
      <div className="p-5 pt-10 text-center flex flex-col justify-center items-center gap-4 xl:gap-8">
        <Tag text={"General questions"} />
        <div className="blurBg text-left w-full flex flex-col justify-center items-center gap-4 xl:gap-6">
          <FaqCard
            title={"How big is the Spirinova's team?"}
            desc="As Spirinova continues to expand its operations and delve into new projects, we are always on the lookout for talented individuals to join our ranks. Our team size is dynamic, adapting to the evolving needs of our projects and the markets we serve.  Our current team is made up of 25+ employees."
          />
          <FaqCard
            title={"How many projects had you completed?"}
            desc="Our portfolio of completed projects is continuously expanding as we consistently take on new challenges and explore new horizons. Currently, Spirinova has completed about 30+ projects. We are expecting more projects to add to the bucket this fall."
          />
          <FaqCard
            title={"How can I request a project quote?"}
            desc="Contact us today at 9971115174 or mail us at amit@spirinova.dev or prince.garg@spirinova.dev to discuss working together to bring your AI project to life."
          />
          <FaqCard
            title={"Do you offer help with urgent projects?"}
            desc="Yes, we provide generative AI technologies, leveraging them to build custom solutions that meet our clients' unique needs.
                                 Spirinova is a pioneering AI-enabled firm that sits at the intersection of innovation, technology, and creativity. We've established a robust ecosystem of multiple technical partners. This network enables us to offer a wide range of AI solutions, from machine learning and natural language processing to computer vision and beyond."
          />
          <FaqCard
            title={"What is the process to start working with you?"}
            desc="Connect with us on 82876 56534 or mail us at  amit@spirinova.dev "
          />
          <FaqCard
            title={"Can I see testimonials about Spirinova?"}
            desc="Click-Me"
            link="/success-stories"
          />
        </div>

        <div className="p-5 xl:px-14 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
          <Tag text={"BLOGS"} />
          <h1 className="heading">We blog on what’s trending in tech</h1>
          <p className="desc xl:w-5/6">
            Scroll through our recent articles — we prepare a good reading for
            tech newbies and pros alike to help make educated business
            decisions.
          </p>
          <div className="flex flex-wrap justify-center items-center gap-5">
            <BlogCard
              img={homblog}
              title="Blockchain - a paradigm shift"
              desc="Blockchain is to business what devops is to IT."
            />
            <BlogCard
              img={blog2}
              title="Blockchain & Web3"
              desc="The blockchain maximizes accessibility, security and accountability."
            />
            <BlogCard
              img={blog3}
              title="Web Applications"
              desc="A web application is a type of application software that can run in a web browser."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
