import React,{useState} from 'react'
import axios from 'axios'
import Tag from '../tag'
import logo from "../../assets/BigLogo.png"
import phone from '../../assets/phone.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseURL } from '../../constants'

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    // console.log(e.target.value)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {

    if (!e.target.checkValidity()) {
      e.preventDefault(); // Prevent submission if invalid
      return;
    }

    e.preventDefault();

    try {
      // Send the form data to your backend
      console.log(formData)
      const response = await axios.post(`${baseURL}/api/submitForm`, formData);
      console.log('Form submitted successfully:', response.data);

      // Optionally, reset the form after successful submission
      setFormData({
        name: '',
        company: '',
        phone: '',
        email: '',
        message: '',
      });

      toast.success('Submitted Successfully!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

      // Send an auto-response to the user
      // const autoResponse = await axios.post('http://localhost:4444/api/submitForm', {
        // to: formData.email,
        // subject: 'Thank you for reaching out!',
        // text: `Dear ${formData.name},\n\nThank you for contacting us. We have received your message and will get back to you soon.\n\nBest regards,\nThe Spirinova Team`,
      // });
      // console.log('Auto-response sent successfully:', autoResponse.data);
    } catch (error) {
   
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      setFormData({
        name: '',
        company: '',
        phone: '',
        email: '',
        message: '',
      });
      toast.error('Error Occurred!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }

  };

  return (
    <form onSubmit={handleSubmit} id='contact' className='z-20 -mb-40 mt-5 xl:mt-10 relative bg-white text-black rounded-3xl mx-6 xl:mx-12 p-5 pb-16 xl:p-10 xl:pb-24 flex flex-col items-start gap-4 xl:gap-6'>
        <Tag text={"Contact us"} />
        <h1 className='heading'>Get in Touch with Us</h1>
        <p className='desc text-[#676D79]'>Let’s talk business! Spirinova custom software development team is perfectly equipped to handle projects of any complexity. Describe your challenge, and we’ll get back to you to discuss how to future-proof your business or get you ahead of the competition..</p>
        <div className='xl:w-4/5 grid grid-cols-2 gap-5'>
            <input required onChange={(e)=> handleChange(e)} name='name' value={formData.name} className='contact-input' type='text' placeholder='Name' />
            <input onChange={(e)=> handleChange(e)} name='company' value={formData.company} className='contact-input' type='text' placeholder='Company (optional) ' />
            <input onChange={(e)=> handleChange(e)} name='phone' value={formData.phone} className='contact-input' type='text' placeholder='Phone No. (Optional)' />
            <input required onChange={(e)=> handleChange(e)} name='email' value={formData.email} className='contact-input' type='text' placeholder='Email' />
            <textarea onChange={(e)=> handleChange(e)} name='message' value={formData.message}  rows="4" className='contact-input col-span-2' type='text' placeholder='Message' />
        </div>
        <button type='submit' className='btn bg-[#181D2B] text-white'>Contact Us</button>

        <div className='z-10 desc font-medium bg-[#EEF3FF] w-full absolute left-0 bottom-0 p-3 xl:p-5 rounded-b-3xl flex items-center gap-5'>
          <h2>Or Give us a call on:</h2>
          <h2 className='flex items-center gap-3'><img className='w-5 sm:w-auto' src={phone} alt="phone"/><a href={`tel:+91 82876 56534`}>{"+91 82876 56534"}</a> </h2>
        </div>

        <div className='z-0 absolute bottom-0 right-0 w-36 xl:w-52'>
            <img className='rounded-br-3xl' src={logo} alt="logo" />
        </div>
        <ToastContainer />
    </form>
  )
}

export default ContactUs
