import React from 'react'
import Navbar from '../navbar'
import Footer from '../footer'

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
        {children}
      <Footer />
      <div className="fixed bottom-2 right-2 lg:bottom-4 lg:right-4 z-40">
        <iframe src="https://app.botstreams.ai/support/66d6c236960c33a527b682c1" className='h-[95vh] lg:w-[500px] lg:h-[600px]'></iframe>
      </div>
    </>
  )
}

export default Layout