import React from "react";
import { baseURL } from "../../../constants";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkHtml from 'remark-html';
import { useLocation } from "react-router-dom";

const Blog = () => {
  const location = useLocation();
  const { state } = location ;
  const data = state && state.data;
  console.log(data,"This is d")
  // const CustomParagraph = ({ children }) => <p style={{ textDecoration: 'underline' }}>{children}</p>;
  return (
    <>
      <div className="p-5 xl:px-14 pt-10 xl:pt-16 flex flex-col gap-5">
        <h1 className="text-3xl xl:text-4xl 2xl:text-6xl font-medium">
          {data?.title}
        </h1>
        <h2 className="desc text-[#9499AB]">
          {data?.summary}
        </h2>
        <img src={`${baseURL}${data?.featuredImage}`} alt="featuredImage" />
        <ReactMarkdown remarkPlugins={[remarkGfm, remarkHtml]}>{data?.content}</ReactMarkdown>
        {/* <p className="desc">The definition of blockchain as stated in IBM website is this -</p> */}
        {/* <div className="desc bg-white p-5 italic rounded-xl border-l-4 border-[#D7DBEF]">
            Blockchain is a shared, immutable ledger that facilitates the process of recording transactions and tracking assets in a business network. An asset can be tangible (a house, car, cash, land) or intangible (intellectual property, patents, copyrights, branding). Virtually anything of value can be tracked and traded on a blockchain network, reducing risk and cutting costs for all involved.
        </div>   */}
        {/* <p className="desc">Although the technical definition does precisely describe what blockchain is but what it does not touch so clearly is how blockchain is so relevant to things happening around the world. One might quickly disappear into the technical jargons that blockchain technology throws in. The intention of this blog to envision the usage of blockchain at a functional level.</p> */}
        {/* <h2 className="heading text-[#1E6AFF]">So here we start...</h2> */}
        {/* <p className="desc">Any action a business, individual, organization performs leaves trails. Those trails more often than not are a valuable asset in a business and hence businesses will often take every effort to retain them either for their own accounting or for regulatory reporting.</p> */}
        {/* <p className="desc">Lets take a financial body - an equity mutual fund that trades equities for example. One of the most basic regulatory requirement for a mutual fund is to declare its holdings every quarter to a regulatory body (SEBI for example) - <span className="underline font-semibold">How Often Do Mutual Funds Report Their Holdings?</span></p> */}
        {/* <p className="desc">That just explains how important a trail is for businesses especially dealing with financial transactions. Not withstanding these requirements entails legal actions and eventually cancellation of the operations.</p> */}
        {/* <p className="desc">This type of reporting happens even today so what's the deal with blockchain? There are 3 fundamental problems with this "out of the system logging/reporting" -</p> */}

        {/* <div className="mt-10 flex flex-col gap-5">
          <h2 className="heading">1.  The BOSS Problem (I report my own stuff)</h2>
          <p className="desc">The reporting party and the party doing the transaction are the same. There are controls on how the reporting should be done but to ensure that the report is correct can happen only by tedious auditing by another third party. Frauds where a mutual fund manager can inflate or manipulate profits and losses or divert funds are not uncommon. Example -</p>
          <p className="desc">Since the institutions are working on silos there is always a chance where multiple parties come together to manipulate something. This is a hard to detect problem and hence been exploited multiple times. Example - <br /><span className="underline font-semibold">Founder of collapsed $1.7 billion mutual fund charged with fraud</span></p>
        </div> */}

        {/* <div className="mt-10 flex flex-col gap-5">
          <h2 className="heading">2.  Trap the rat problem</h2>
          <p className="desc">Since the institutions are working on silos there is always a chance where multiple parties come together to manipulate something. This is a hard to detect problem and hence been exploited multiple times. Some examples -</p>
          <ul className="list-disc flex flex-col gap-5 desc ml-5 xl:ml-10">
            <li>
              Axis bank fraud where the mutual fund passed on information to dealers before they were going to bulk buy a stock to increase prices - <span className="underline font-semibold">Axis Mutual Fund saga</span>
            </li>
            <li>
              <span className="underline font-semibold">The libor scandal -</span> The scandal arose when it was discovered that global banks were falsely inflating or deflating their rates and submitting wrong rates in order to manipulate libor rates.
            </li>
            <li>
              <span className="underline font-semibold">The lehman brothers bankrupcy -</span> where banks gave loans without proper documentation and the rating agencies helped the selling by including over three trillion dollars of loans to homebuyers with bad credit and undocumented incomes
            </li>
          </ul>
        </div> */}

        {/* <div className="my-10 flex flex-col gap-5">
          <h2 className="heading">3.  Recursive Corruption</h2>
          <p className="desc">The government of India passed on the Lokapal Bill in 2019 and RTI act in as a deterrent to corruption. RTI - Right to information enables any citizen to question the activities of a government. The peculiar problem which this poses is who decides that the lokpal himself is not corrupt (recursive corruption :-) ) and whether he should have more power over the head of state. This is always an issue with centralized auditing. How do you ensure the auditor himself is not corrupt?</p>
        </div> */}

        {/* <p className="desc">Blockchain proposes a unique proposition to all these problems</p> */}

        {/* <div>
          <p className="desc font-semibold">Solution to the boss problem - <span className="text-[#1E6AFF]">‘Shift of power’</span>  </p>
          <p className="desc">Shifting of power is not a new concept. Long back businesses suffered their time and money because people would bargain at the payment counter. Some times back the power of bargaining was removed from person sitting in the counter as he was given no control over the prices and discounts, those were all pre set in the system.</p>
        </div> */}
        {/* <p className="desc">Similar to this blockchain shifts the responsibility of reporting from business to technology. Blockchain enables transaction with an inbuilt logging. The logging is tamper proof. There is no way the logs can be manipulated because the technology takes care of that. So essentially blockchain separates the reporting layer from the actual doer. So the boss still remains but on camera.</p> */}

        {/* <div>
          <p className="desc font-semibold">Solution to trap the rat problem</p>
          <p className="desc">Blockchain in a way centralizes the logging (yes they are stored in decentralized peers). But what it achieves finally is a big repository of transaction logs that can be queried. These logs can serve an immense purpose for building intelligent systems to find out malicious actors. Blockchain ensures that the log is accurate and every activity from start to finish is logged.</p>
        </div> */}
        {/* <p className="desc">Lets now shift our focus from finance to other fields where this may be useful. Lets take the data industry where regulations are still just coming along. Unlike finance, govt has just started to regulate data owned by big organizations. There is hardly any governance yet on how a company can use (read misuse) consumer data. There are no mechanisms set yet between govt and the companies to report data usage once collected. So in a sense this industry is 1 step behind the finance industry where the reporting has already set in. Bigger companies allow third party apps which can further misuse the data in any way they want.</p> */}
        {/* <p className="desc">There are other industries which suffer from unjustified brokerages (agriculture for example), monopoly etc. where the problem at the roots are similar.</p> */}

        {/* <div>
          <p className="desc font-semibold">Conclusion</p>
          <p className="desc">Blockchain is to business what devops is to IT. It has a potential to strengthen business processes and regulations by making them transparent and traceable similar to what logs and monitoring systems do to an IT application. It changes in a very fundamental way how businesses are operating today. Blockchain is not going to give the next Google, the next Uber or the next Amazon. Blockchain is going to change how Google, Uber or Amazon operate. Blockchain is not going to necessarily add new systems and applications, it is going to change the way systems, applications and authorities interact. New companies will emerge where older ones are resistant to change. It is going to have a tremendous impact on industries that have issues with reporting, frauds, unjustified brokerages or other problems that grow their roots on non transparent systems. Blockchain brings businesses to public eyes and so in a subtle way transforms them to behave well.</p>
        </div> */}
        {/* <p className="desc">The IT industry once in while changes gears that take a leap not necessarily in a continuity from the past technologies. Be it the console giving way to the UI, the on premise machines giving way to cloud infrastructure or the open source technologies giving tough fight to the technologies owned by big giants, IT has been in the forefront of changes for multiple decades now. Sooner than later this technology will grow roots and become a modus operandi for multiple businesses and authorities. The community is growing and we are on the verge of a new IT revolution - the era of blockchain - a paradigm shift.</p> */}
      </div>
    </>
  );
};

export default Blog;