import React, { useState } from 'react'
import logo from "../../assets/logo2.gif"
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, NavLink } from 'react-router-dom';
import { BsX } from "react-icons/bs";

const Navbar = () => {
    const [menu, setMenu] = useState(false);

    const MenuTitle = ({title, link, partial}) => (
        <NavLink to={link}>
        {({ isActive }) => (
            <h2 className={`text-sm 2xl:text-base font-medium flex items-center gap-2 cursor-pointer hover:text-blue-600 ${isActive && "rounded-lg px-3 py-2 bg-[#F3F3F3] text-blue-600"}`}>
                {title}
            </h2>
        )}
        </NavLink>
    )

  return (
    <div className='bg-white z-50 relative'>
    <div className='p-3 px-4 lg:px-8 flex justify-between items-center'>
        <Link to='/'>
            <img className='w-44' src={logo} alt="logo" />
        </Link>
        <div className='hidden xl:flex items-center gap-8'>
            <MenuTitle title={"Home"} link='/' />
            <MenuTitle title={"Services"} link='/services' />
            <MenuTitle title={"Success Stories"} link='/success-stories' partial={true} />
            <MenuTitle title={"Technologies"} link='/technologies' />
            <MenuTitle title={"Industries"} link='/industries' />
            {/* <MenuTitle title={"Products"} link='/products' /> */}
            <MenuTitle title={"Company"} link='/company' />
            {/* <MenuTitle title={"Blogs"} link='/blogs' partial={true} /> */}
        </div>
        {/* <Link to='#contact' className='hidden xl:block'>
            <button className='btn bg-cusBlack'>
                Get in Touch
            </button>
        </Link> */}
        <button className='btn bg-cusBlack hidden xl:block' onClick={() => window.location.href = '#contact'}>
            Get in Touch
        </button>
        <button className='xl:hidden z-50' onClick={() => setMenu(!menu)}>
            {!menu ? <RxHamburgerMenu className='text-2xl' /> : null }
        </button>
    </div>
    <div className={`xl:hiden h-full overflow-hidden transition-all text-lg font-medium flex flex-col gap-5 bg-white fixed top-0 z-10 ${menu ? "w-full p-3" : "w-0"}`}>
    <div className='lg:px-8 flex justify-between items-center'>
        <Link to='/'>
            <img className='w-44' src={logo} alt="logo" />
        </Link>
        <button onClick={() => setMenu(!menu)}>
            {!menu ? <RxHamburgerMenu className='text-2xl' /> : <BsX className='text-3xl' /> }
        </button>
    </div>
        <Link to='/' onClick={() => setMenu(false)}>Home</Link>
        <Link to='/services' onClick={() => setMenu(false)}>Services</Link>
        <Link to='/success-stories' onClick={() => setMenu(false)}>Success Stories</Link>
        <Link to='/technologies' onClick={() => setMenu(false)}>Technologies</Link>
        <Link to='/industries' onClick={() => setMenu(false)}>Industries</Link>
        {/* <Link to='/'>Products</Link> */}
        <Link to='/company' onClick={() => setMenu(false)}>Company</Link>
        {/* <Link to='/blogs' onClick={() => setMenu(false)}>Blogs</Link> */}
    </div>
    </div>
  )
}

export default Navbar