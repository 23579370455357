import React from 'react'
import 'animate.css';

const Tag = ({text, tech}) => {
  return (
    <h3 className={`wow animate__fadeIn w-fit text-center uppercase text-xs font-semibold py-1.5 px-2.5 rounded-md ${tech ? "text-black bg-[#D2E1FE]" : "text-[#1E6AFF] bg-[#1E6AFF1F]"}`}>
        {text}
    </h3>
  )
}

export default Tag