import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { baseURL } from '../../constants'; 
import 'animate.css';
import WOW from 'wowjs';

const Industries = () => {
  const [data, setData] = useState([]);
  const [activeBtn, setActiveBtn] = useState({});

  useEffect(() => {
    getData();
  }, []) 

  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: 'animate__animated',
    }).init();
  }, [])


  const getData = () => {
    axios.get(`${baseURL}/api/industry`)
    .then((res) => {
      console.log(res.data.data.attribute,"This is industry");
      setData(res.data.data.attributes);
      setActiveBtn(res.data.data.attributes.options[0]);
    })
  }

  console.log(activeBtn,"Thsi is data");

  // const Card = () => (
  //   <div className='pickgradient w-full sm:w-64 sm:h-80 xl:w-80 xl:h-96 rounded-lg text-white relative'>
  //     <img className='w-full h-full object-cover z-[-10] relative rounded-lg' src='https://cdn.pixabay.com/photo/2017/11/27/21/31/computer-2982270_1280.jpg' alt="card" />
  //     <div className='absolute bottom-5 left-5 w-[87%]'>
  //       <h1 className='text-lg xl:text-2xl font-semibold'>Product Consulting</h1>
  //       <p className='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  //     </div>
  //   </div>
  // )

  return (
    <>
      <div className="p-5 xl:px-14 pt-10 xl:pt-16 flex flex-col gap-5">
        <h1 className="wow animate__fadeIn text-3xl xl:text-4xl 2xl:text-6xl font-medium">
          {data?.title}
        </h1>
        <div className="wow animate__fadeIn lg:mt-3" data-wow-delay="0.5s">
          <p className="desc">{data?.desc}</p>
        </div>
      </div>

      <div className='wow animate__fadeIn m-5 xl:mx-14 bg-white rounded-2xl' data-wow-delay="1s">
        <div className='h-96 sm:h-64 md:h-72 xl:h-80 2xl:h-96 relative'>
          <img className='w-full h-full object-cover rounded-2xl' src={`${baseURL}${activeBtn?.image}`} alt="heroImage" />
        <div className='text-white text-3xl font-bold bg-transparent absolute bottom-0 left-9 leading-[64px] '>
          <h1>{activeBtn?.title}</h1>
        </div>
        {/* <div className='glass p-3 text-base text-white absolute bottom-5 left-5 w-[87%] sm:w-1/2 md:w-2/5 xl:w-1/3 2xl:w-1/5'>
            {data?.options?.map((item) => (
              <button className={`text-left rounded-lg w-full p-1 px-2 ${activeBtn?.title === item.title && "bg-white text-black" }`} onClick={() => setActiveBtn(item)}>
                {item.title}
              </button>
            ))}
            </div>*/}
        </div>
        <div className='flex flex-col lg:flex-row'>
          <div className='glass p-3 text-base text-gray-500 m-8 lg:w-1/3 2xl:w-1/5'>
            {data?.options?.map((item) => (
              <button className={`text-left rounded-lg w-full p-1 px-2 transition duration-50 hover:bg-gray-200 ${activeBtn?.title === item.title && "bg-white text-black" }`} onClick={() => setActiveBtn(item)}>
                {item.title}
              </button>
            ))}
            </div>
        <div className='p-5 xl:px-14 mt-3 lg:w-2/3 2xl:w-4/5'>
          <h2 className='text-[#9499AB] text-base xl:text-lg'>{activeBtn?.desc}</h2>
          <div className='mt-8 flex flex-col gap-4'>
            {activeBtn?.info?.map((item) => (
              <h3 className='text-sm xl:text-base'>{item}</h3>
            ))}
          </div>
          <div className='mt-8 grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
            {activeBtn?.data?.map((item) => (
              <h3 className='text-sm xl:text-base lg:w-1/2'>{item}</h3>
            ))}
          </div>
          </div>
        </div>
      </div>

      {/* <div className='flex flex-col justify-center items-center gap-6'>
        <div className='p-5 xl:px-14 grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-10'>
          <Card />
          <Card />
          <Card />
          <Card />
        </div>
        <button className='btn border border-black text-black'>
          Show More
        </button>
      </div> */}
    </>
  )
}

export default Industries