import React from "react";
import { RiDoubleQuotesL } from "react-icons/ri";
import avatar from '../../assets/avatar.png'
import niraj from '../../assets/niraj.png'
import igor from '../../assets/igor.png'
import anuj from '../../assets/anuj.png'
import sammmco from '../../assets/sammmco.png'
import pratyush from '../../assets/pratyush.png'

const Clients = () => {
    let screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const ClientCard = ({bg, span, color,img,name,desig,desc}) => {
    return (
        <div style={{ background: bg, color: color }} className={`relative p-6 rounded-2xl col-span-${screenWidth > 640 ? span : '1'} drop-shadow-lg overflow-hidden`}>
            <div className='flex items-center gap-5'>
                <img src={img} alt={name} />
                <div>
                    <h1 className='font-semibold text-base xl:text-xl'>{name}</h1>
                    <h2 className='text-sm xl:text-base'>{desig}</h2>
                </div>
            </div>
            <p className='text-sm xl:text-base mt-4'>{desc}</p>
            <div className="absolute -top-8 right-0">
                <RiDoubleQuotesL className="text-[110px] opacity-20" />
            </div>
        </div>
    );
  };

  return (
    <div>
      <div className="border my-4"></div>
      <div className="mt-10 grid sm:grid-cols-2 xl:grid-cols-3 items-stretch gap-5 2xl:gap-8">
        <ClientCard bg="#181D2B" span='2' color="white" img={avatar} name={"Will Moreno"} desig={"Founder, TextRP"} desc={"From the very beginning of the project, Spirinova has shown a deep understanding of our needs and requirements. They have been proactive in their communication and have been consistently delivering on-time and within budget. Their team has also been extremely flexible and responsive to our evolving needs, providing valuable insights and guidance every step of the way. Overall, we are very impressed with Spirinova's work and would highly recommend them to anyone looking for a reliable and skilled software development partner."}/>
        <ClientCard bg="#FFFFFF" span='1' img={niraj} name={"Neeraj Sinha"} desig={"Co-founder Fanzania"} desc={"Amit has developed the Fanzania app for us. He now is developing incremental features of the app with his own company “Spirinova Technologies Pvt ltd”. Fanzania is a fantasy cricket app with a unique subscription model. I must admire the development services by them which is always within budget, on time and with full transparency from start to execution to completion of the deliverables"} />
        <ClientCard bg="#1E6AFF" span='1' color="white" img={anuj} name={"Anuj Pratap Singh"} desig={"Director @Pratap Buildtech"} desc={"It was an honor working with Spirinova team on the hyperlocal delivery app. The innovative design and impeccable user experience they created in a short period of time, I guess I couldn’t have gotten elsewhere easily. I recommend Spirinova for their capabilities to innovate."} />
        <ClientCard bg="#6B7081" span='1' color="white" img={igor} name={"Igor"} desig={"Entrepreneur"} desc={"It was a great pleasure working with Amit. I enjoyed the process organisation and consistency of the work. I would recommend Spirinova’s service to those looking to get technical work done."}/>
        <ClientCard bg="#181D2B" span='1' color="white" img={sammmco} name={"Sam Cohen"} desig={"Founder @Stealth Startup"} desc={"It was very pleasing working experience with Spirinova team on mining system development.I was impressed by the depth of their knowledge, ability to solve problems quickly and enthusiasm. I would recommend Spirinova to people, who want to get their complex software work done"}/>
        <ClientCard bg="#FFFFFF" span='2'  img={pratyush} name={"Piyush Bhargav"} desig={"Founder, Anamcara Technologies (Tanjio app)"} desc={"Team Spirinova has done a commendable job in end to end development of our product 'Tanjio'. They have not only understood the requirements really well but also have given lots of inputs on how product features should be visually appealing and integrated with each other. It was a complex set up of a consumer facing app, merchant facing app and an admin panel. Team has developed the entire stack within 3 months and launched it in play store. Highly recommend services of Spirinova for the product buildouts."}/>
      </div>
    </div>
  );
};

export default Clients;