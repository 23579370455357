import React, { useEffect, useState } from "react";
import Tag from "../../../components/tag";
import axios from 'axios';
import { Link, useLocation } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import { baseURL } from "../../../constants"; 
import 'animate.css';
import WOW from 'wowjs';

const Story2 = () => {
    const location = useLocation();
  const { state } = location ;
  const id = state && state.id;
  const [data, setData] = useState([]);
  

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: 'animate__animated',
    }).init();
  }, [])

  const getData = () => {
    axios.get(`${baseURL}/api/success-stories/${id}`)
    .then((res) => {
      console.log(res.data.data.attribute,"This is res");
      setData(res.data.data.attributes);
    })
  }

  return (
    <>

      <div className="p-5 xl:px-14 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
      
        <Tag text={data?.tag} />
        <h1 className="wow animate__fadeIn heading" data-wow-delay="0.5s">{data?.title}</h1>
        <div className="relative">
          <div className="leftBlur w-[200px] h-[200px] xl:w-[500px] xl:h-[350px] absolute left-0 xl:-left-20 top-0"></div>
          <img src={`${baseURL}${data?.heroImage}`} className="wow animate__fadeIn" data-wow-delay="1s" alt="heroImage" />
          <div className="rightBlur w-[200px] h-[200px] xl:w-[500px] xl:h-[350px] absolute right-0 xl:-right-20 bottom-0"></div>
        </div>
        <div className="w-full p-5 pt-14 xl:p-10 xl:pt-28 bg-white rounded-3xl -mt-12 xl:-mt-14">
          {data?.section1?.desc.map((item, i) => (
            <p key={i} className="desc">{item}</p>
          ))}
            <div className="mt-5 xl:mt-10 flex justify-center items-center gap-4">
              {data?.section1?.tag.map((item, i) => (
                <button key={i} className="btn border text-black border-black bg-transparent">{item}</button>
              ))}
            </div>
        </div>
      </div>

      {data?.pointList &&<div className="p-5 xl:px-14 m-5 xl:mx-14 bg-white rounded-2xl desc flex flex-col gap-2 xl:gap-4">
        <p>{data?.pointList?.title}</p>
        <ul className="ml-8 flex flex-col list-disc list-outside gap-2 xl:gap-4">
          {data?.pointList?.points?.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        <p>{data?.pointList?.end}</p>
      </div>}

      {data?.tech?.title || data?.tech?.image ? 
      <div className="relative bg-blue-200 p-5 xl:px-14 xl:mt-16 m-5 xl:mx-14 rounded-2xl flex flex-col justify-start items-center gap-4 xl:gap-6 h-[35vh] lg:h-[50vh] mb-[10vh] sm:mb-[20vh] lg:mb-[40vh]">
        {data?.tech?.title && <Tag text={'technologies USED'} tech />}
        <p className="desc font-medium text-center">{data?.tech?.title}</p>
        {data?.tech?.image && 
        <div className="h-full lg:w-1/2 lg:h-5/6 absolute bottom-[-25%] sm:bottom-[-40%] md:bottom-[-45%] lg:bottom-[-10%] xl:bottom-[-25%]">
          <img className="w-full h-full object-contain" src={`${baseURL}${data?.tech?.image}`} alt="techImage" />
        </div>}
      </div> : null}

      <div className="text-center my-20 flex flex-col items-center">
        <p className="desc font-medium">Website link</p>
        <Link to={data?.link}>
        <button className="mt-3 w-fit btn bg-[#1E6AFF] flex justify-center items-center gap-4">
          Visit Now <BsArrowUpRight />
        </button>
        </Link>
      </div>
    </>
  );
};

export default Story2;