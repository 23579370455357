import React, { useEffect, useState } from "react";
import {  TbArrowBackUp } from "react-icons/tb";
import Tag from "../../../components/tag";
import { BsArrowUpRight } from "react-icons/bs";
import { CiBoxList } from "react-icons/ci";
import { TbCompass } from "react-icons/tb";
import { BsStars } from "react-icons/bs";
import { LuWallet } from "react-icons/lu";
import axios from 'axios';
import { Link } from "react-router-dom"; 
import { baseURL } from "../../../constants";
import 'animate.css';
import WOW from 'wowjs';
import { useLocation } from "react-router-dom";
// import { IoArrowBackCircleSharp } from "react-icons/io5";

const Story = () => {
  const location = useLocation();
  const { state } = location ;
  const id = state && state.id;
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: 'animate__animated',
    }).init();
  }, [])

  const getData = () => {
    axios.get(`${baseURL}/api/success-stories/${id}`)
    .then((res) => {
      console.log(res.data.data.attribute,"This is res");
      setData(res.data.data.attributes);
    })
  }

  const Icons = ({ icon }) => (
    <div className="bg-[#393E4D] p-2 text-white text-2xl rounded-lg" >
      {icon}
    </div>
  )

  return (
    <>
      <div className="p-0 xl:px-14 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6 relative">
        <Link to = { (`/success-stories#${id}`)} className="btn hidden lg:flex text-blue-700 font-light absolute top-5 left-10 gap-2">
          <TbArrowBackUp size={25} />
          <span className="text-s font-light text-blue-500 text-center">Back to Success Stories</span>
        </Link>

        <Tag text={data?.tag} />
        <h1 className='wow animate__fadeIn heading' data-wow-delay="0.5s">{data?.title}</h1>
        <div className="relative ">
          <div className="leftBlur w-[0] h-[0] xl:w-[250px] xl:h-[350px] absolute left-0 xl:-left-20 top-0"></div>
          <img src={`${baseURL}${data?.heroImage}`} className="w-full  wow animate__fadeIn" data-wow-delay="1s" alt="heroImage" />
          <div className="rightBlur w-[0] h-[0] xl:w-[250px] xl:h-[350px] absolute right-0 xl:-right-20 bottom-0"></div>
        </div>
      </div>

      <div className="bg-white xl:mt-16 m-5 xl:mx-14 rounded-2xl flex flex-col lg:flex-row justify-between items-stretch">
        <div className={`p-5 xl:px-14 pt-10 flex flex-col justify-around items-start gap-8 ${data?.section1?.image && "lg:w-3/5"}`}>
          {data?.section1?.logo && <img className="w-24 md:w-auto" src={`${baseURL}${data?.section1?.logo}`} alt="logo"/>}
          {data?.section1?.desc.map((item) => (
            <p className="desc">{item}</p>
          ))}
          <div className="flex gap-4">
            {data?.section1?.tag?.map((item) => (
              <button className="btn border text-black border-black bg-transparent">{item}</button>
            ))}
          </div>
        </div>
        {data?.section1?.image &&
        <div className="w-full lg:flex lg:w-2/5">
          <img className="rounded-r-2xl w-full h-full object-cover" src={`${baseURL}${data?.section1?.image}`} alt="section" />
        </div>}
      </div>

      {data?.pointList &&<div className="p-5 xl:px-14 m-5 xl:mx-14 bg-white rounded-2xl desc flex flex-col gap-2 xl:gap-4">
        <p>{data?.pointList?.title}</p>
        <ul className="ml-8 flex flex-col list-disc list-outside gap-2 xl:gap-4">
          {data?.pointList?.points?.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
        <p>{data?.pointList?.end}</p>
      </div>}

      {data?.images && <div className="p-5 xl:px-14 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
        <h1 className="heading text-[#1E6AFF]">{data?.images?.title}</h1>
        <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-center lg:justify-between gap-3">
          {data?.images?.image?.map((item) => (
            <img className="w-52 xl:w-80" src={`${baseURL}${item}`} alt="imageRow" />
          ))}
        </div>
      </div>}

      {data?.imageText && <div className="p-5 xl:px-14 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6">
        <h1 className="heading text-[#1E6AFF]">{data?.imageText?.title}</h1>
        <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-center lg:justify-between gap-3">
          {data?.imageText?.images?.map((item) => (
            <div className="w-52 h-64 xl:w-80 xl:h-80 drop-shadow relative text-left">
              <img className="w-full h-full object-cover rounded-2xl" src={`${baseURL}${item?.image}`} alt="imageText" />
              <ul className="list-disc text-white text-lg font-medium absolute top-5 left-8 xl:w-72">
                <li>{item?.text}</li>
              </ul>
            </div>
          ))}
        </div>
      </div>}

      {data?.gridSection && <div className="p-5 xl:px-14 pt-10 xl:pt-16 text-left flex flex-col justify-center items-center gap-4 xl:gap-6">
        <h1 className="heading text-[#1E6AFF]">{data?.gridSection?.title}</h1>
        <div className={`grid md:grid-cols-${data?.gridSection?.section4 ? '3' : '2'} gap-5 mt-8`}>
          <div className="flex flex-col gap-5">
            <div className="bg-[#181D2B] text-white p-5 rounded-2xl">
              {data?.gridSection?.section1?.title && <div className="flex items-center gap-4">
                <Icons icon={<CiBoxList />} />
                <h2 className="text-lg font-medium">{data?.gridSection?.section1?.title}</h2>
              </div>}
              <p className="text-sm mt-5">{data?.gridSection?.section1?.desc}</p>
            </div>
            <div className="bg-[#181D2B] text-white p-5 rounded-2xl">
              {data?.gridSection?.section2?.title && <div className="flex items-center gap-4">
                <Icons icon={<TbCompass />} />
                <h2 className="text-lg font-medium">{data?.gridSection?.section2?.title}</h2>
              </div>}
              <p className="text-sm mt-5">{data?.gridSection?.section2?.desc}</p>
            </div>
          </div>
          <div className="bg-[#181D2B] text-white p-5 rounded-2xl">
            {data?.gridSection?.section3?.title && <div className="flex items-center gap-4">
              <Icons icon={<BsStars />} />
              <h2 className="text-lg font-medium">{data?.gridSection?.section3?.title}</h2>
            </div>}
            <p className="text-sm mt-5">{data?.gridSection?.section3?.desc}</p>
          </div>
          {data?.gridSection?.section4 && <div className="bg-[#181D2B] text-white p-5 rounded-2xl">
            <div className="flex items-center gap-4">
              <Icons icon={<LuWallet />} />
              <h2 className="text-lg font-medium">{data?.gridSection?.section4?.title}</h2>
            </div>
            <p className="text-sm mt-5">{data?.gridSection?.section4?.desc}</p>
          </div>}
        </div>
        {data?.gridSection?.section5 && <div className="bg-[#181D2B] text-white p-5 rounded-2xl w-full">
          <div className="flex items-center gap-4">
            <Icons icon={<CiBoxList />} />
            <h2 className="text-lg font-medium">{data?.gridSection?.section5?.title}</h2>
          </div>
          <p className="text-sm mt-5">{data?.gridSection?.section5?.desc}</p>
        </div>}
      </div>}

      {data?.tech?.title || data?.tech?.image ?
      <div className="relative bg-blue-200 p-5 xl:px-14 xl:mt-16 m-5 xl:mx-14 rounded-2xl flex flex-col justify-start items-center gap-4 xl:gap-6 h-[35vh] lg:h-[50vh] mb-[10vh] sm:mb-[20vh] lg:mb-[40vh]">
        {data?.tech?.title && <Tag text={'technologies USED'} tech />}
        <p className="desc font-medium text-center">{data?.tech?.title}</p>
        {data?.tech?.image && 
        <div className="lg:w-1/2 lg:h-5/6 absolute bottom-[-10%] sm:bottom-[-40%] md:bottom-[-45%] lg:bottom-[-10%] xl:bottom-[-25%]">
          <img className="w-full h-full object-contain" src={`${baseURL}${data?.tech?.image}`} alt="techImage" />
        </div>}
      </div> : null}

      <div className="text-center my-20 flex flex-col items-center">
        <p className="desc font-medium">Website link</p>
        <Link to={data?.link}>
        <button className="mt-3 w-fit btn bg-[#1E6AFF] flex justify-center items-center gap-4">
          Visit Now <BsArrowUpRight />
        </button>
        </Link>
      </div>
    </>
  );
};

export default Story;