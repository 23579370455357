import React from 'react'

const BlogCard = ({img, title, desc}) => {
  return (
    <div className='text-white text-left relative'>
        <img src={img} alt={title} />
        <div className='absolute bottom-5 left-5'>
            <h2 className='text-sm md:text-base font-medium'>{title}</h2>
            <h3 className='text-xs md:text-sm w-5/6 mt-4'>{desc}</h3>
        </div>
    </div>
  )
}

export default BlogCard