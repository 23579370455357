import React from 'react'
import axios from 'axios'
import { useRef } from 'react';
import { Link } from 'react-router-dom'
import Tag from '../../components/tag'
import building from '../../assets/building.png'
import amit from '../../assets/amit.png'
import umesh from '../../assets/umesh.png'
import linkin from '../../assets/in.png'
import avatar1 from '../../assets/avatar1.png'
import strong from '../../assets/strong.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// import company from '../../assets/company.png'
import artlog3 from '../../assets/artlog2.png'
import altlog3 from '../../assets/altlog2.png'
import redlog3 from '../../assets/redlog2.png'
import radlog3 from '../../assets/radlog2.png'
import talog3 from '../../assets/talog2.png'
import tanlog3 from '../../assets/tanlog2.png'
import xlog3 from '../../assets/xlog2.png'
import athlog3 from '../../assets/athlog2.png'
import belog3 from '../../assets/belog2.png'
import daylog3 from '../../assets/daylog2.png'
import freshlog3 from '../../assets/freshlog2.png'
import speedlog3 from '../../assets/speedlog2.png'
import oqlog3 from '../../assets/oqlog2.png'
import comp from '../../assets/comp.png'
import comp1 from '../../assets/comp3.png'
import comp2 from '../../assets/comp3.png'
import comp3 from '../../assets/comp3.png'
import { IoMailOutline } from "react-icons/io5";
import { BsTelephone } from 'react-icons/bs'
import 'animate.css';
import WOW from 'wowjs';
import { useEffect, useState } from 'react'

const Company = () => {
    useEffect(() => {
        new WOW.WOW({
          live: false,
          animateClass: 'animate__animated',
        }).init();
      }, [])

    const ClientLogo = ({img}) => (
        <div className={`indusCard p-3 w-35 h-10 px-5 flex justify-center items-center gap-3 `}>
        <div className='   flex justify-center items-center  rounded-lg'>
            <img className='w-3/4' src={img} alt="clientLogo" />
        </div>
        {/* <h2 className='text-base xl:text-xl font-medium break-keep'>{title}</h2> */}
    </div>
        // <div className='bg-[#DDDDE9] w-40 h-10 flex justify-center items-center rounded-lg'>
        //     <img className=' ' src={img} alt="clientLogo" />
        // </div>
    )

    const leadershipRef = useRef(null);

    const AchivData = ({num, title, delay, link}) => (
        <Link to={link}>
        <div className='wow animate__fadeIn bg-white rounded-2xl text-center p-5 w-32 xl:w-52 xl:h-28 flex flex-col justify-center items-center' data-wow-delay={delay}>
            <h1 className='text-[#1E6AFF] text-xl xl:text-3xl font-bold'>{num}</h1>
            <h2 className='text-black font-semibold text-sm xl:text-base'>{title}</h2>
        </div>
        </Link>
    )

    const TeamCard = ({img, name, post, desc, email, phone, display, id}) => (
        <div className={`bg-white p-5 rounded-2xl flex flex-col gap-3 xl:gap-6 ${display ? "lg:col-span-2" : ""}`}>
            <div className='flex justify-between items-end'>
                <div className='flex items-end gap-5'>
                    <img className='w-20 xl:w-40' src={img} alt={name} />
                    <div>
                        <h2 className='text-xl xl:text-3xl font-semibold'>{name}</h2>
                        <div className='flex items-center gap-4'>
                            <h3 className='text-[#6B7081] text-sm xl:text-xl font-medium'>{post}</h3>
                            <div className='border-x h-5'></div>
                            <a href="https://www.linkedin.com/in/your-linkedin-profile" target="_blank" rel="noreferrer">
                                 <img src={linkin} alt="LinkedIn Profile" />
                            </a>

                        </div>
                    </div>
                </div>
                {display ? <div className='hidden md:flex items-center gap-8'>
                    <h3 className='text-[#1E6AFF] text-sm xl:text-lg underline flex items-end gap-3'><IoMailOutline className='text-black text-3xl' /><a href={`mailto:${email}`}>{email}</a></h3>
                    <h3 className='text-[#1E6AFF] text-sm xl:text-lg underline flex items-end gap-3'><BsTelephone className='text-black text-3xl' /><a href={`tel:${phone}`}>{phone}</a></h3>
                </div> : null}
            </div>
            <div className='text-base xl:text-xl text-[#181D2B]'>
                {desc}
            </div>
            {!display ? <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center'>
                <h3 className='text-[#1E6AFF] text-sm xl:text-lg underline flex items-end gap-3'><IoMailOutline className='text-black text-3xl' /><a href={`mailto:${email}`}>{email}</a></h3>
                <h3 className='text-[#1E6AFF] text-sm xl:text-lg underline flex items-end gap-3'><BsTelephone className='text-black text-3xl' /><a href={`tel:${phone}`}>{phone}</a></h3>
            </div> : null}
        </div>
    )

    // const [selectedHobbies, setSelectedHobbies] = useState('');
    // const [filteredMembers, setFilteredMembers] = useState([]);

    const TeamMembers = ({img, name, role, hobbies, selectedHobbies}) => (
        <div className='m-5 flex flex-col items-center justify-center gap-1'>
            <img className='w-25 h-25 rounded-full bg-black'src={img} alt={name} />
            <h2 className='text-xl text-black  font-semibold'>{name}</h2>
            <h3 className='text-lg text-[#6B7081] font-medium'>{role}</h3>

        </div>
    )

   

    // const handleHobbiesChange = (e) => {
    //     setSelectedHobbies(e.target.value);
    // };

    // useEffect(() => {
    //     if (selectedHobbies === '') {
    //         setFilteredMembers([]);
    //     } else {
    //         const membersData = [
    //             { img: avatar1, name: "Amit Kumar", role: "CEO", hobbies: "writer" },
    //             { img: avatar1, name: "Vikas Bansal", role: "Developer", hobbies: "singer, writer" },
    //             { img: avatar1, name: "Jaskaran", role: "Developer", hobbies: "dancer" },
    //             { img: avatar1, name: "Sumant", role: "Developer", hobbies: "pet owner" },
    //             { img: avatar1, name: "Aditi", role: "Human Resource", hobbies: "dancer, pet owner" },
    //             { img: avatar1, name: "shubhangi", role: "Finance", hobbies: "writer" },
    //             { img: avatar1, name: "Prince", role: "Designer", hobbies: "dancer, writer" },
    //             { img: avatar1, name: "Rahul", role: "RnD", hobbies: "pet owner" },
    //             { img: avatar1, name: "Sanjeev", role: "AI", hobbies: "writer" },
    //             { img: avatar1, name: "Shubham", role: "Software engineer", hobbies: "dancer" },
    //             { img: avatar1, name: "Ankit", role: "Software engineer", hobbies: "pet owner" },
    //             { img: avatar1, name: "Rahul", role: "Software engineer", hobbies: "writer" }
    //         ];

    //         const filteredData = membersData.filter(member => member.hobbies.includes(selectedHobbies));
    //         setFilteredMembers(filteredData);
            
          
    //     }
    // }, [selectedHobbies]);

    

    // const [teamMembers, setTeamMembers] = useState([
    //     { id: 1, name: "John Doe", role: "Developer", img: "https://via.placeholder.com/150" }, 
    // ]);

    // useEffect(()  =>{
    //     const fetchTeamMembers = async () => {
    //         try {
    //             const response = await axios.get('http://localhost:4444/api/team-members');
    //             console.log("Raw fetched data:", response.data);  
    //             if ( Array.isArray(response.data)) {
    //             setTeamMembers(prevMembers => [...prevMembers, ...response.data]);
    //         } else if (typeof response.data === 'object') {
    //             setTeamMembers(prevMembers => [...prevMembers, response.data]);
    //                 setTeamMembers([]); 
    //         } else {
    //             console.error('Invalid data format received:', response.data);
    //         }
    //             // console.log("Fetched data:", response.data);
    //             // setTeamMembers(response.data);
    //             // console.log("State after setting:", teamMembers);
    //         } catch (error) {
                
    //             console.log('loading failure:', error);
    //         }
    //     };
    //     fetchTeamMembers();
    // }, []);

    // useEffect(() => {
    //     console.log("Updated teamMembers state:", teamMembers);
    // }, [teamMembers]);

// useEffect(() => {
//     if (selectedhobbies === 'all') {
//         setFilteredMembers(TeamMembers);
//     } else {
//         setFilteredMembers(TeamMembers.filter(member => member.hobbies.includes(selectedhobbies)));
//     }
// }, [selectedhobbies]);

    

  return (
    <>
        <div className='companyBg relative text-white lg:h-[65vh] 2xl:h-[90vh] flex flex-col justify-center items-start gap-5 lg:gap-8 xl:gap-10 p-5 py-10 xl:p-10'>
            <h1 className='wow animate__fadeIn text-3xl xl:text-4xl 2xl:text-6xl  font-medium lg:w-2/5'>Collaboration, expertise, excellence - our team defines success.</h1>
            <h2 className='wow animate__fadeIn 2xl:text-lg lg:w-1/4 '>Fuelled by a spirit of relentless innovation, we push the boundaries of what's possible, constantly seeking new ways to deliver value to our clients and stakeholders.</h2>
            {/* <div className='wow animate__fadeIn flex gap-5 mt-5'>
                <button className='btn bg-[#1E6AFF]'>Download Portfolio</button>
            </div> */}
            {/* <div className='absolute bottom-[-42vh] sm:bottom-[-24vh] md:bottom-[-9vh] xl:-bottom-12 right-0 left-0 mx-auto transform translate-y-10 flex flex-wrap justify-center items-center gap-5 xl:gap-10'>
                <AchivData num={"50 +"} title="Successful projects" delay="0.3s" link={'/success-stories'} />
                <AchivData num={"100 %"} title="Successful Rate" delay="0.6s" link={'/success-stories'} />
                <AchivData num={"20 +"} title="Large scale projects" delay="0.9s" link={'/success-stories'}  />
                <AchivData num={"10 +"} title="Countries served" delay="1.2s" link={'/success-stories'}  />
                <AchivData num={"35 +"} title="Years of founders experience" delay="1.5s" link={'#leadership'} />
            </div> */}
        </div>
        
        {/* <div className='pt-[45vh] sm:pt-[25vh] md:pt-[10vh] xl:p-10'></div> */}

        <div className='p-5 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6'>
            <Tag text={"About Us"}/>
            <h1 className='heading'>One stop product development and IT Consulting Company</h1>
            <div className='mt-4 xl:mt-7 w-full grid grid-cols-1 md:grid-cols-3 gap-4 xl:gap-6'>
                <div className='text-white text-left bg-[#181D2B] p-5 xl:p-10 rounded-3xl flex flex-col lg:flex-row justify-between items-center gap-5 md:col-span-3'>
                    <p className='wow animate__fadeIn text-sm xl:text-base xl:w-1/2'>Spirinova Technologies, incorporated on 2022 offers end to end product consulting and AI driven product development services. With our generative AI-driven product development services, we optimise on time, effort and cost to build scalable products. Our offering includes product ideation to market launch. We provide development services for MVP, product and custom software development for startups and enterprises across all major contemporary and futuristic technologies.</p>
                    <img className='-mb-5 -xl:mb-10' src={building} alt="building" />
                </div>
                <div className='text-white   text-left bg-[#1E6AFF] p-5 xl:p-10 rounded-3xl flex flex-col justify-between items-start gap-5 md:col-span-1'>
                    <p className='text-lg xl:text-2xl'>Get ready to transform your ideas into reality and your operations and processes into growth enablers.</p>
                    <Link to={'#contact'}>
                    <button className='btn bg-transparent border'>Get Started</button>
                    </Link>
                </div>
                <div className='bg-white text-left rounded-3xl drop-shadow-xl p-5 xl:p-10 md:col-span-2 flex justify-between items-center'>
                    <p className=' wow animate__fadeIn text-sm xl:text-base'>We offer a bouquet of services and cater to sectors across the board. Our services ensure efficiency, transparency, security, and automation for all business processes to drive excellence and growth. We bring the highest quality of product consulting, business consulting and product development services in Web 3  development, Android/iOS App development, Blockchain/Web3, Artificial Intelligence, Internet of Things (loT), Augmented & Virtual Reality (AR/VR) technologies, Quantum Computing and Computer Security.</p>
                </div>
            </div>
        </div>

        <div className='logoSwiper p-5 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6'>
            <Tag text={"OUR VALUABLE CLIENTS"}/>
            <h1 className='heading'>Proud to be reliable technology partner</h1>
            <Swiper
                speed={5000}
                slidesPerView={9}
                spaceBetween={3}
                loop= {true}
                autoplay={{
                delay: 1,
                freeMode: true,
                disableOnInteraction: false,
                }}
                showsPagination={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="wow animate__fadeIn w-full mt-4 xl:mt-7"
                breakpoints={{
                    300: {
                      slidesPerView: 2,
                    },
                    640: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                    1280: {
                      slidesPerView: 7,
                    },
                    1536: {
                      slidesPerView: 9,
                    },
                }}
            >
                <SwiperSlide className='w-fit'><ClientLogo img={freshlog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={artlog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={altlog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={athlog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={redlog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={radlog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={talog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={tanlog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={belog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={xlog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={daylog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={speedlog3}/></SwiperSlide>
                <SwiperSlide className='w-fit'><ClientLogo img={oqlog3}/></SwiperSlide>
            </Swiper>
        </div>

        <div id="leadership" ref={leadershipRef} className='p-5 pt-10 xl:pt-16 flex flex-col justify-center items-center gap-4 xl:gap-6'>
            <Tag text={"meet the team"}/>
            <h1 className='heading'>Leadership team</h1>
            <h1 className='font-semibold text-xl xl:text-3xl text-[#9499AB]'>Spirinova Founders</h1>
            <div className='mt-4 xl:mt-7 w-full grid lg:grid-cols-2 gap-4 xl:gap-8'>
                <TeamCard img={amit} name="Amit Kumar" post="CEO" desc="BTech from IIT BHU, 17+ years of experience. Ex- VMWare, Credit Suisse, Intel, Samsung. Serial entrepreneur, with deep tech expertise (AI, Blockchain, AR/VR, IoT). Tech enthusiast and a problem solver. At Spirinova heads R&D and growth related to deep tech and new product initiatives." email="amit@spirinova.dev" phone="+91-99711 15174" />
                <TeamCard img={umesh} name="Umesh Tripathi" post="CTO" desc="BTech from NIT Bhopal. 17+ years of exp. Ex- Microsoft & VP at Barclays. Expertise in building scalable Technology platforms and products with millions of users. Tech enthusiast with business acumen. At Spirinova manages complete software development lifecycle and heads all deliveries." email="umesh@spirinova.dev" phone="+91-86056 33693" />

            </div>
        </div>

        <div className='p-5 pt-10 xl:pt-16 text-center flex flex-col justify-center items-center gap-4 xl:gap-6'>
            <h1 className='text-cusBlue text-xl xl:text-3xl font-semibold'>We are a strong team of</h1>
            <h2 className='text-[#676D79] text-base xl:text-xl md:w-4/5'>When it comes to innovation, we help figure out what’s your thing. Don’t save your ideation attempts for another day. Check if we have something similar to what you have in mind.</h2>
            <div className='flex justify-center items-center gap-5 xl:gap-10'>
                <img className='w-10 lg:w-20' src={avatar1} alt="team" />
                <img className='w-10 lg:w-20' src={comp} alt="team" />
                <img className='w-10 lg:w-20' src={comp1} alt="team" />
                <img className='w-10 lg:w-20' src={comp2} alt="team" />
                <img className='w-10 lg:w-20' src={comp3} alt="team" />
            </div>
            <div className='relative' >
            {/* <select className='border border-black text-blue-500 text-sm font-semibold px-3' style={{position: 'absolute', top:-15, right: 80}} defaultValue='dive deep' onChange={handleHobbiesChange} value={selectedHobbies}>
                    {/* <option disabled value=''>Select</option> */}
                    {/* <option value='option1'>writer</option>
                    <option value = 'option2'>singer</option>
                    <option value = 'option3'>dancer</option>
                    <option value = 'option4'>pet owner</option> */}
                {/* </select> */} 
                <div className='grid grid-cols-1 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3 gap-5 px-40 xl:gap-10' style={{ justifyContent: 'center' }} >
                {/* {filteredMembers.map(member => ( */}
                     {/* <TeamMembers key={member.name} img={member.img} name={member.name} role={member.role} hobbies={member.hobbies} /> */}
                {/* ))} */}
                            {/* <TeamMembers img={avatar1} name="Amit Kumar" role="CEO" hobbies="writer" />
                            <TeamMembers img={avatar1} name="Vikas Bansal" role="Developer" hobbies='singer, writer' />
                            <TeamMembers img={avatar1} name="Jaskaran" role="Developer" hobbies='dancer' />
                            <TeamMembers img={avatar1} name="Sumant" role="Developer" hobbies='pet owner' />
                            <TeamMembers img={avatar1} name="Aditi" role="Human Resource" hobbies='dancer, pert owner' />
                            <TeamMembers img={avatar1} name="shubhangi" role="Finance" hobbies="writer" />
                            <TeamMembers img={avatar1} name="Prince" role="Designer" hobbies="dancer, writer" />
                            <TeamMembers img={avatar1} name="Rahul" role="RnD" hobbies="pet owner" />
                            <TeamMembers img={avatar1} name="Sanjeev" role="AI" hobbies="writer" />
                            <TeamMembers img={avatar1} name="Shubham" role="Software engineer" hobbies="dancer" />
                            <TeamMembers img={avatar1} name="Ankit" role="Software engineer" hobbies="pet owner" />
                            <TeamMembers img={avatar1} name="Rahul" role="Software engineer" hobbies="writer" /> */}
                            
                            
                {/* <p>Team Members Component</p>
                {Array.isArray(teamMembers) && teamMembers.length > 0 ? (
                            teamMembers.map(member => (
                                <div key={member.id} className="p-2 text-center shadow-lg m-2 rounded-lg" >
                                <img  src={member.img} alt={member.name} className="w-24 h-24 object-cover rounded-full mx-auto"/>
                                <p className="mt-2 font-bold">{member.name}</p>
                                <p>{member.role}</p>
                            </div>
                        ))
                       ) : ( <p>No data</p>)}
                    </div> */}
                
            </div>
            <div>
                <img src={strong} alt="strong" />
            </div>
        </div>
        </div>
      </>
    );
  }
  
  export default Company;
