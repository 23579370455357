import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Layout from './components/layout';
import SuccessStories from './pages/success-stories';
import Story from './pages/success-stories/components/story';
import Story2 from './pages/success-stories/components/story2';
import Tanjio from './pages/success-stories/components/tanjio';
import Blogs from './pages/blogs';
import Blog from './pages/blogs/components/blog';
import Company from './pages/company';
import Industries from './pages/industries';
import Technologies from './pages/technologies';
import Services from './pages/services';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="company" element={<Company />} />
          <Route path="industries" element={<Industries />} />
          <Route path="services" element={<Services />} />
          <Route path="technologies" element={<Technologies />} />

          <Route path="success-stories" element={<SuccessStories />} />
          <Route path="success-stories/story" element={<Story />} />
          <Route path="success-stories/story-2" element={<Story2 />} />
          <Route path="success-stories/tanjio" element={<Tanjio />} />

          <Route path="blogs" element={<Blogs />} />
          <Route path="blogs/blog" element={<Blog />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;