import React, { useEffect, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import axios from 'axios'
import { Link } from "react-router-dom";
import { baseURL } from "../../constants";
import 'animate.css';
import WOW from 'wowjs';

const Blogs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []) 

  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: 'animate__animated',
    }).init();
  }, [])

  const getData = () => {
    axios.get(`${baseURL}/api/blogs`)
    .then((res) => {
      console.log(res.data.data,"This is blogs");
      setData(res.data.data);
    })
  }

    const Card = ({ title, desc, img, data }) => (
        <Link to="/blogs/blog" state={{ data: data }} className='wow animate__fadeIn pickgradient rounded-lg text-white relative'>
          <img className='w-full h-full object-cover z-[-10] relative rounded-lg' src={`${baseURL}${img}`} alt="card" />
          <div className='absolute bottom-5 left-5 w-[87%]'>
            <h1 className='text-sm xl:text-base font-semibold'>{title}</h1>
            <p className='text-xs mt-2'>{desc}</p>
          </div>
        </Link>
      )

  return (
    <>
      <div style={{ backgroundImage: `url(${baseURL}${data[0]?.attributes?.featuredImage})`}} className="companyBg relative h-[45vh] lg:h-[50vh] 2xl:h-[70vh] flex flex-col justify-center items-start gap-5 lg:gap-8 xl:gap-10 p-5 py-10 xl:p-10">
        <Link to="/blogs/blog" state={{ data: data[0]?.attributes }} className="wow animate__fadeIn absolute bottom-[-20%] left-8 xl:left-12">
          <div className="bg-white p-6 xl:p-8 rounded-xl flex flex-col gap-2 xl:gap-4 w-80 drop-shadow-lg">
            <div className="flex justify-between items-center">
                {data[0]?.attributes?.tag ? <h3 className="bg-[#1E6AFF] p-1 px-2 text-sm font-medium rounded-md text-white">{data[0]?.attributes?.tag}</h3> : <div></div>}
                <FiArrowUpRight className='text-3xl' />
            </div>
            <h1 className="text-xl xl:text-3xl font-medium ">{data[0]?.attributes?.title}</h1>
            <h2 className="text-base text-[#9499AB]">{data[0]?.attributes?.summary}</h2>
          </div>
        </Link>
      </div>
      
      <div className="mt-[15vh] xl:mt-[25vh] p-5 xl:px-14 grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5">
        {data?.map((item) => {
          return(
            <Card key={item.id} data={item?.attributes} title={item?.attributes?.title} desc={item?.attributes?.summary} img={item?.attributes?.featuredImage} />
          )
        })}
      </div>
    </>
  );
};

export default Blogs;